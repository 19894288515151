/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const WALL_TO_WALL_SUBZONES_TABLE_COLUMNS = [
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.Subzone',
    id: 'subzone',
    headerCellTextAlign: 'start' as const,
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-24))',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.TimeStarted',
    id: 'timeStarted',
    headerCellTextAlign: 'start' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-28) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.Counter',
    id: 'counter',
    headerCellTextAlign: 'start' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.ReCounter',
    id: 'reCounter',
    headerCellTextAlign: 'start' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.NetAdjusted',
    id: 'netAdjusted',
    headerCellTextAlign: 'start' as const,
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.AbsolutePCAccuracy',
    id: 'absolutePCAccuracy',
    headerCellTextAlign: 'start' as const,
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.PCAdjustments',
    id: 'pcAdjustments',
    headerCellTextAlign: 'start' as const,
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-19) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.Variance',
    id: 'variances',
    headerCellTextAlign: 'start' as const,
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-22) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.LocationProgress',
    id: 'locationProgress',
    headerCellTextAlign: 'center' as const,
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.TotalLocations',
    id: 'totalLocations',
    headerCellTextAlign: 'end' as const,
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-22) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.LocationsAssigned',
    id: 'locationsAssigned',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-22) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.WallToWallPhases.Subzone.TableColumns.LocationsCompleted',
    id: 'locationsCompleted',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-22) * 2)',
    isSorted: true,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'var(--st-unit-10)' },
];
