/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  GetReleaseGroupLaneParams,
  GetReleaseGroupParams,
  ReleaseOrderLaneRequest,
  RunReplenishmentParams,
} from '@mdm/types/service.type.ts';
import { apiService } from '@shared/services/apiService.ts';
import { ORDER_RELEASE_ENDPOINTS, RELEASE_GROUP_ENDPOINTS } from '@shared/constants/routes.ts';
import { paginatedParseAndLog } from '@mdm/utils/utils.ts';
import {
  ReleaseGroupLaneListSchema,
  ReleaseGroupListSchema,
} from '@outbound/schemas/releaseGroupSchema.ts';

export const releaseGroupService = {
  getReleaseGroups: async (options: GetReleaseGroupParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        ...(options?.groupTypes && {
          groupTypes: options.groupTypes,
        }),
        ...(options?.orderType && {
          orderType: options.orderType,
        }),
        ...(options?.parentReleaseGroupId && {
          parentReleaseGroupId: options.parentReleaseGroupId,
        }),
      },
    };

    const response = await apiService.post(ORDER_RELEASE_ENDPOINTS.GET_ORDER_RELEASES, payload);
    return paginatedParseAndLog(ReleaseGroupListSchema, response.data);
  },
  getReleaseGroupLanes: async (options: GetReleaseGroupLaneParams) => {
    const payload = {
      searchPage: {
        page: options.currentPage,
        ...(options?.pageSize && { size: options.pageSize }),
        ...(options?.sortBy && { sortBy: options.sortBy }),
        ...(options?.direction && { direction: options.direction.toUpperCase() }),
      },
      searchCriteria: {
        ...(options?.layoutTypeCds && {
          layoutTypeCds: options.layoutTypeCds,
        }),
        ...(options?.layoutStatusCds && {
          layoutStatusCds: options.layoutStatusCds,
        }),
      },
    };

    const response = await apiService.post(ORDER_RELEASE_ENDPOINTS.GET_LANES, payload);
    return paginatedParseAndLog(ReleaseGroupLaneListSchema, response.data);
  },
  runReplenishment: async (releaseGroups: RunReplenishmentParams) => {
    return apiService.post(RELEASE_GROUP_ENDPOINTS.RUN_REPLENISH, releaseGroups);
  },
  releaseOrder: async (releaseGroups: ReleaseOrderLaneRequest) => {
    return apiService.post(RELEASE_GROUP_ENDPOINTS.RELEASE_ORDER, releaseGroups);
  },
};
