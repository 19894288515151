/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useOutletContext } from 'react-router-dom';

import { View, Text, Loader } from '@az/starc-ui';

import styles from '../OrderTabs.module.scss';
import { useAtom } from 'jotai';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
  selectedOrdersAtom,
  selectedReleaseOrderTypeAtom,
  selectedRootReleaseOrdersAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import { OrderSection } from '@outbound/components/OrderSection/OrderSection';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE } from '@shared/constants/constants.ts';
import {
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
} from '@outbound/constants/constants.ts';
import { RootReleaseGroupAccordion } from '@outbound/components/ReleaseGroupAccordion/RootReleaseGroupAccordion.tsx';
import { useEffect } from 'react';

export const TransferOrders = () => {
  /* Constants */
  const [getSelectionStatusLabel]: [() => string] = useOutletContext();

  /*Atoms*/
  const [, setSelectedOrders] = useAtom(selectedOrdersAtom);
  const [, setSelectedRootReleaseOrders] = useAtom(selectedRootReleaseOrdersAtom);
  const [laneConfirmationItem] = useAtom(laneConfirmationItemAtom);
  const [, setSelectedReleaseOrderType] = useAtom(selectedReleaseOrderTypeAtom);
  const [inLaneConfirmation] = useAtom(inLaneConfirmationAtom);
  const { releaseGroupsData, isFetching: isReleaseOrderLoading } = useGetReleaseGroups({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    orderType: RELEASE_GROUPS_ORDER_TYPE.TRANSFER,
    groupTypes: [RELEASE_GROUPS_GROUP_TYPE.ROOT],
  });

  useEffect(() => {
    setSelectedReleaseOrderType(RELEASE_GROUPS_ORDER_TYPE.TRANSFER);
  }, [setSelectedReleaseOrderType]);

  // Clear selected orders when navigating to this page
  useEffect(() => {
    setSelectedRootReleaseOrders([]);
    setSelectedOrders([]);
  }, [setSelectedOrders, setSelectedRootReleaseOrders]);

  return (
    <View direction="column" padding={6} className={styles['order-release']}>
      {!inLaneConfirmation && <Text>{getSelectionStatusLabel()}</Text>}
      <View direction="column" className={styles['order-release__content']}>
        <OrderSection>
          {isReleaseOrderLoading && <Loader color="primary" />}
          {(!inLaneConfirmation || laneConfirmationItem) &&
            (releaseGroupsData?.content || []).map((releaseGroup) => (
              <RootReleaseGroupAccordion
                key={releaseGroup.groupId}
                releaseGroup={releaseGroup}
                orderType={RELEASE_GROUPS_ORDER_TYPE.TRANSFER}
              />
            ))}
        </OrderSection>
      </View>
    </View>
  );
};
