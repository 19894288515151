/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useQuery } from '@tanstack/react-query';

import { replenishmentService } from '../replenishmentService';
import { GetReplenishmentParams, DownloadReplenishmentParams } from '@inbound/types/service.type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';

export const useGetReplenishment = (options: GetReplenishmentParams) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const {
    data: replenishmentData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['replenishment', JSON.stringify(options)],
    queryFn: () => replenishmentService.getReplenishment(options),
    staleTime: 0,
  });
  useEffect(() => {
    if (isError) {
      handleNotification(
        NOTIFICATION_TYPES.ERROR,
        t('ReplenishmentDashboard.Notification.GetReplenishment.Error')
      );
    }
  }, [isError, handleNotification, t, isFetching]);
  return { replenishmentData, isLoading, isError, refetch };
};

export const useDownloadReplenishment = (options: DownloadReplenishmentParams, enabled = true) => {
  const {
    data: downloadData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryFn: () => replenishmentService.downloadReplenishment(options),
    enabled,
  });
  return { downloadData, isLoading, isFetching, isError, refetch };
};
