/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAtom } from 'jotai';
import { t } from 'i18next';

import { View } from '@az/starc-ui';

import { CombinedTabs } from '@shared/components/CombinedTabs/CombinedTabs';
import { PAGE_URLS, ROUTES } from '@shared/constants/routes';
import {
  inLaneConfirmationAtom,
  laneConfirmationItemAtom,
  replenishedAtom,
  selectedOrdersAtom,
  selectedOrdersLaneAtom,
  selectedSubzonesAtom,
} from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import { displayOptionsAtom } from '@outbound/atoms/outboundMatrix/outboundMatrixAtoms';
import {
  MatrixSortOptions,
  OrderReleaseTabTitles,
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
} from '@outbound/constants/constants';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE } from '@shared/constants/constants.ts';

export const OrderTabs = () => {
  /*Atoms*/
  const [selectedOrders, setSelectedOrders] = useAtom(selectedOrdersAtom);
  const [selectedSubzones, setSelectedSubzones] = useAtom(selectedSubzonesAtom);
  const [inLaneConfirmation, setInLaneConfirmation] = useAtom(inLaneConfirmationAtom);
  const [, setLaneConfirmationItem] = useAtom(laneConfirmationItemAtom);
  const [, setSelectedStoresLane] = useAtom(selectedOrdersLaneAtom);
  const [, setReplenishedStores] = useAtom(replenishedAtom);
  const [, setDisplayOptions] = useAtom(displayOptionsAtom);

  /* Constants */
  const location = useLocation();
  const isTransferOrderTab = location.pathname.includes(ROUTES.Transfer);
  const isDifOrderTab = location.pathname.includes(ROUTES.DIF);
  const isOrderReleasePage =
    location.pathname.includes(ROUTES.ORDER_RELEASE_DIF) ||
    location.pathname.includes(ROUTES.ORDER_RELEASE_TRANSFER);

  const { releaseGroupsData: difReleaseGroupsData } = useGetReleaseGroups(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: MAX_PAGE_SIZE,
      orderType: RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER,
      groupTypes: [RELEASE_GROUPS_GROUP_TYPE.ROOT],
    },
    isTransferOrderTab || isDifOrderTab
  );

  const { releaseGroupsData: transferReleaseGroupsData } = useGetReleaseGroups(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: MAX_PAGE_SIZE,
      orderType: RELEASE_GROUPS_ORDER_TYPE.TRANSFER,
      groupTypes: [RELEASE_GROUPS_GROUP_TYPE.ROOT],
    },
    isTransferOrderTab || isDifOrderTab
  );

  const tabs = [
    {
      name: t(OrderReleaseTabTitles.OUTBOUND_MATRIX),
      value: 'replenishment',
    },
    {
      name: t(OrderReleaseTabTitles.ORDER_RELEASE),
      value: 'order-release-dif',
    },
    {
      name: t(OrderReleaseTabTitles.ORDER_REVIEW),
      value: 'new-store-and-backup',
    },
    {
      name: t(OrderReleaseTabTitles.HISTORICAL_ORDER),
      value: 'long-tail-distribution',
    },
  ];

  const subTabs = [
    {
      name: t(OrderReleaseTabTitles.DIF),
      numberOfItems: difReleaseGroupsData?.totalElements || 0,
      value: 'order-release-dif',
    },
    {
      name: t(OrderReleaseTabTitles.TRANSFER),
      numberOfItems: transferReleaseGroupsData?.totalElements || 0,
      value: 'order-release-transfer',
    },
  ];

  const getSelectionStatusLabel = () => {
    let label = 'OutboundMatrix.OrderRelease.StoreSelected';
    let count = 0;

    if (isTransferOrderTab || isDifOrderTab) {
      label = 'OutboundMatrix.OrderRelease.OrderSelected';
      count = selectedOrders.length;
    } else if (selectedOrders.length) {
      label = 'OutboundMatrix.OrderRelease.StoreSelected';
      count = selectedOrders.length;
    } else if (selectedSubzones.length) {
      label = 'OutboundMatrix.OrderRelease.SubzoneSelected';
      count = selectedSubzones.length;
    }

    return t(label, {
      count: count,
    });
  };

  // useEffect added to clear order release states
  useEffect(() => {
    setInLaneConfirmation(false);
    setSelectedOrders([]);
    setSelectedSubzones([]);
    setLaneConfirmationItem(null);
    setSelectedStoresLane([]);
    setReplenishedStores([]);
    setDisplayOptions((options) => ({
      ...options,
      sortBy: MatrixSortOptions.ROUTE,
    }));
  }, [
    location.pathname,
    setDisplayOptions,
    setInLaneConfirmation,
    setLaneConfirmationItem,
    setReplenishedStores,
    setSelectedOrders,
    setSelectedStoresLane,
    setSelectedSubzones,
  ]);

  return (
    <View
      direction="column"
      height="100%"
      attributes={{ style: { width: '100%', minWidth: 'fit-content' } }}
    >
      {!inLaneConfirmation && (
        <>
          <CombinedTabs key="main-tabs" tabs={tabs} rootPath={PAGE_URLS.OUTBOUND_CONTROL_DESK} />
          {isOrderReleasePage && (
            <CombinedTabs
              key="sub-tabs"
              tabs={subTabs}
              rootPath={PAGE_URLS.OUTBOUND_CONTROL_DESK}
            />
          )}
        </>
      )}
      <Outlet context={[getSelectionStatusLabel]} />
    </View>
  );
};
