/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { CYCLE_COUNT_TYPE_CD } from '@inventory/constants/constants';
import { CycleCountDetailsHeaderProps } from '@inventory/pages/CycleCounts/CycleCountDetails/CycleCountDetailsComponents/CycleCountDetailsHeader/CycleCountDetailsHeader.types';
import { t } from 'i18next';

export const getHeaderDetails = (
  headerContent: CycleCountDetailsHeaderProps[]
): {
  headerTitle: string;
} => {
  if (!Array.isArray(headerContent) || headerContent.length === 0) {
    return { headerTitle: '' };
  }
  const { countTypeCd, year = '', phase = '', week = '' } = headerContent[0] || {};

  let headerTitle = '';

  switch (countTypeCd) {
    case CYCLE_COUNT_TYPE_CD.RBCC:
      headerTitle = t('CycleCount.RBCCHeaderText', {
        year,
        period: phase,
        week,
      });
      break;
    case CYCLE_COUNT_TYPE_CD.WALL_TO_WALL:
      headerTitle = t('CycleCount.WallToWallHeaderText', {
        year,
        phase,
      });
      break;
    default:
      headerTitle = '';
  }

  return {
    headerTitle,
  };
};
