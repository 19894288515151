/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@mdm/types/service.type.ts';
import { PaginatedResponseSchema } from '@mdm/schemas/paginatedResponseSchema.ts';
import { LogError } from '@ofm/classes/LogError.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';
import { SESSION_DC_ID_KEY } from '@shared/constants/storageConstants.ts';
import { SLOT_UNIT } from '@mdm/constants/constants.ts';
import { WarehouseProductType } from '@shared/types/schema.type.ts';

export const parseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): z.infer<T> | undefined => {
  const parsedData = schema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as z.infer<typeof schema> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // eslint-disable-next-line no-console
      console.log('ZOD ERROR', parsedData);
    }
    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

export const paginatedParseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): PaginatedResponse<T> | undefined => {
  const paginatedSchema = PaginatedResponseSchema(schema);
  const parsedData = paginatedSchema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as PaginatedResponse<T> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // eslint-disable-next-line no-console
      console.log('ZOD ERROR', parsedData);
    }

    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

/**
 * Normalize distinct name
 * @param distinctName
 * @param type
 */
export const normalizeDistinctName = (distinctName: string, type: string): string => {
  let name = '';
  if (distinctName) {
    name = distinctName.split(',').find((v) => v.indexOf(type) > -1) || '';

    if (name) {
      name = name.split('=')[1];
    }
  }

  return name;
};

export const getSelectedDC = () => {
  let value = sessionStorage.getItem(SESSION_DC_ID_KEY);
  value = value !== null ? JSON.parse(value) : '';
  return value;
};

export const getSlotProductQuantity = (
  slotUnit: string,
  warehouseProducts?: WarehouseProductType | null
) => {
  let qty = 0;
  const unitPerCaseQty = parseFloat(warehouseProducts?.physicalPackingUnits?.unitPerCaseQty ?? '0');
  const unitPerTierQty = parseFloat(warehouseProducts?.physicalPackingUnits?.unitPerTierQty ?? '0');
  const tiersPerPalletQty = parseFloat(
    warehouseProducts?.physicalPackingUnits?.tiersPerPalletQty ?? '0'
  );
  const innerQty = warehouseProducts?.uomConversion?.whseCalculatedInnerQty ?? 0;
  const outerQty = warehouseProducts?.uomConversion?.whseOuterQty ?? 0;

  switch (slotUnit) {
    case SLOT_UNIT.PALLET:
      qty = unitPerCaseQty * unitPerTierQty * tiersPerPalletQty;
      break;
    case SLOT_UNIT.INNER:
      qty = innerQty;
      break;
    case SLOT_UNIT.OUTER:
      qty = outerQty;
      break;
    case SLOT_UNIT.EACH:
      qty = 1;
      break;
  }

  return qty;
};
