/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { PODashboardDividerRowType } from '@inbound/types/types';
import {
  PO_DASHBOARD_SEARCH_BY,
  PO_HISTORY_SEARCH_BY,
  REPLENISHMENT_SORT_BY,
  REPLENISHMENT_STATUS,
  REPLENISHMENT_TIME,
} from './constants';

const PO_DASHBOARD_ROWS = [
  {
    priority: true,
    arrivalTime: new Date('03/07/23 10:16:00'),
    vendorName: 'Ring & Pinion Service Inc.',
    poNumber: '20738877',
    receiptId: 'c69a2a6bc85745168e213a91067f2141',
    door: '',
    location: 'Yard',
    position: 'inYard',
    carrierName: 'US Express',
    trailerNumber: '5241',
    locationType: 'Yard',
    receivingDoor: 'RC07',
    lines: 0,
    totalLines: 7,
    pieces: 0,
    totalPices: 14,
    commodity: 'OIL',
    poType: 'LTL',
    skusWithFPS: 870,
    totalSkusWithFPS: 1000,
    users: [],
    status: 'READY_FOR_SIGNATURE',
    actionDropdown: true,
  },
];

const LOCATION_TYPES = [
  {
    label: 'Yard',
    value: 'Yard',
  },
  {
    label: 'Door',
    value: 'Door',
  },
  {
    label: 'Lane',
    value: 'Lane',
  },
  {
    label: 'Aisle',
    value: 'Aisle',
  },
];

const PO_CATEGORIES = [
  {
    label: 'All POs',
    value: 'BOTH',
  },
  {
    label: 'Assigned POs',
    value: 'ASSIGNED',
  },
  {
    label: 'Unassigned POs',
    value: 'UNASSIGNED',
  },
];

const ARRIVAL_TIMES = [
  {
    label: 'Last 24 hours',
    value: 'last24Hours',
  },
  {
    label: '24-48 hours ago',
    value: '24-48HoursAgo',
  },
  {
    label: '48-72 hours ago',
    value: '48-72HoursAgo',
  },
  {
    label: '72+ hours ago',
    value: '72+HoursAgo',
  },
];

const PO_SHIFT = [
  {
    label: 'First Shift',
    value: 'FIRST_SHIFT',
  },
];

const SEARCH_PROPERTIES = {
  PO: {
    queryParam: 'po',
    search: 'search',
  },
};

const DIVIDER_LABELS: { [key: string]: PODashboardDividerRowType } = {
  '24 Hours': {
    dividerLabel: '24 Hours',
    receiptId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '240000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
  '48 Hours': {
    dividerLabel: '48 Hours',
    receiptId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '480000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
  '72 Hours': {
    dividerLabel: '72 Hours',
    receiptId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '720000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
  '72+ Hours': {
    dividerLabel: '72+ Hours',
    receiptId: 'c69a2a6bc85745168e213a91067f2141',
    priority: false,
    arrivalTime: new Date(),
    vendorName: '',
    poNumber: '721000000000000000',
    door: '',
    location: '',
    lines: 0,
    totalLines: 0,
    pieces: 0,
    totalPices: 0,
    commodity: '',
    poType: '',
    skusWithFPS: 0,
    totalSkusWithFPS: 0,
    users: [],
    status: '',
  },
};

const TASK_SEARCH_MOCK_Data = [
  {
    taskNo: 'ginti-abc123',
    departmentId: 'PO',
    domainType: 'Inbound',
    statusCd: 'NEW',
    taskTypeCd: 'PO_RECEIVING',
    startTs: '2024-05-17T06:04:24.964Z',
    endTs: '2024-05-17T06:04:24.964Z',
    priority: 7000,
    totalPoints: 70,
    completedPoints: 70,
    createUserId: 'mosborne',
    createTs: '2024-01-25T23:29:10.354476',
    lastModifiedUserId: 'mosborne',
    lastModifiedTs: '2024-01-25T23:29:10.354476',
    attributes: [
      {
        attributeKey: '7a78f3bbb601425305073e60636e1d79',
        key: 'PO',
        value: 'PO12345',
      },
    ],
    assignments: [
      {
        userId: 'wdallas',
        status: 'NEW',
        startTs: '2024-01-25T23:29:10.354476',
        endTs: '2024-01-25T23:29:10.354476',
        completedPoints: 70,
        executionPriority: 7001,
        createUserId: 'mosborne',
        createTs: '2024-01-25T23:29:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        activities: [
          {
            userId: 'user002',
            status: 'NEW',
            startTs: '2024-01-25T23:29:10.354476',
            endTs: '2024-01-25T23:29:10.354476',
            completedPoints: 70,
            activity_attributes: [
              {
                attributeKey: '7a78f3bbb601425305073e60636e1d79',
                key: 'PO',
                value: 'PO12345',
              },
            ],
          },
        ],
      },
    ],
    comments: [
      {
        userId: 'user003',
        comment: 'NEW',
        createTs: '2024-01-25T23:29:10.354476',
      },
    ],
  },
];

const REPLENISHMENT_DATA = {
  pageable: {
    offset: 0,
    sort: {
      sorted: true,
      unsorted: false,
      empty: false,
    },
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    unpaged: false,
  },
  sort: {
    sorted: true,
    unsorted: false,
    empty: false,
  },
  content: [
    {
      replenishments: {
        outs: 102.42,
        vehicle: 'RANY',
        src: 'PAA4303C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'BridgeStone Radial Tyre',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'FER1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 10,
        totalReplenQty: 10,
        completedReplenQty: 0,
        replenTypeCd: 'MANUAL',
        priority: 7000,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: new Date().toISOString(),
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 102.42,
        vehicle: 'RANY',
        src: 'PAA4303C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00101',
        partNbrId: 'A5834',
        prodLongDesc: 'BridgeStone',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FAE0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'FER1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 10,
        totalReplenQty: 10,
        completedReplenQty: 0,
        replenTypeCd: 'MANUAL',
        priority: 7000,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: new Date().toISOString(),
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 120.18,
        vehicle: 'FANY',
        src: 'PPO4748C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'Dural Brake Drum',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'BMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 30,
        totalReplenQty: 10,
        completedReplenQty: 8,
        replenTypeCd: 'MANUAL',
        priority: 7005,
        statusCd: 'ASSIGNED',
        createUserId: 'mosborne',
        createTs: new Date().toISOString(),
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'ABC',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 102.42,
        vehicle: 'RANY',
        src: 'PAA4303C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'Twintick shock',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'FER1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 10,
        totalReplenQty: 10,
        completedReplenQty: 6,
        replenTypeCd: 'MANUAL',
        priority: 6000,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: '2024-01-25T23:29:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 10.5,
        vehicle: 'SANY',
        src: 'PHY4583C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: '3 Outlet Wall Tap',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'LMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 38,
        totalReplenQty: 10,
        completedReplenQty: 0,
        replenTypeCd: 'MANUAL',
        priority: 6000,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: '2024-02-25T23:30:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 10.5,
        vehicle: 'SANY',
        src: 'PHY4583C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'Twintube Shock',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'LMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 38,
        totalReplenQty: 10,
        completedReplenQty: 0,
        replenTypeCd: 'MANUAL',
        priority: 6000,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: '2024-02-25T23:30:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 12.18,
        vehicle: 'FANY',
        src: 'PPO4748C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'Dural Brake Drum',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'BMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 30,
        totalReplenQty: 10,
        completedReplenQty: 10,
        replenTypeCd: 'MANUAL',
        priority: 7000,
        statusCd: 'ASSIGNED',
        createUserId: 'mosborne',
        createTs: '2024-01-25T23:29:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 18.5,
        vehicle: 'SANY',
        src: 'PHY4583C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:11:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: '3 Outlet Wall Tap',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'LMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 38,
        totalReplenQty: 10,
        completedReplenQty: 0,
        replenTypeCd: 'MANUAL',
        priority: 7010,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: '2024-01-25T23:11:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 10.42,
        vehicle: 'RANY',
        src: 'PAA4303C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'Twintick shock',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'FER1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 10,
        totalReplenQty: 10,
        completedReplenQty: 6,
        replenTypeCd: 'MANUAL',
        priority: 7015,
        statusCd: 'NEW',
        createUserId: 'mosborne',
        createTs: '2024-01-24T23:29:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'wdallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 9.25,
        vehicle: 'FANY',
        src: 'APO4748C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: 'Dural Brake Drum',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'FME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'BMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 30,
        totalReplenQty: 10,
        completedReplenQty: 10,
        replenTypeCd: 'MANUAL',
        priority: 6000,
        statusCd: 'ASSIGNED',
        createUserId: 'mosborne',
        createTs: '2024-01-21T23:29:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-21T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'tmallas',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
    {
      replenishments: {
        outs: 10.5,
        vehicle: 'SANY',
        src: 'PHY4583C',
        replenId: 'RP1987000000009007',
        srcRequestedTs: '2024-01-25T23:29:10.354476',
        productId: 'P00100',
        partNbrId: 'A5834',
        prodLongDesc: '3 Outlet Wall Tap',
        layoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
        layoutName: 'AME0101W',
        layoutType: 'SLOT',
        parentLayoutDistinctName: 'SZ=FER1, ZN=FM',
        parentLayoutName: 'LMR1',
        layoutMax: 100,
        layoutMin: 10,
        layoutThreshold: 60,
        originalReplenQty: 38,
        totalReplenQty: 10,
        completedReplenQty: 5,
        replenTypeCd: 'MANUAL',
        priority: 7000,
        statusCd: 'ASSIGNED',
        createUserId: 'mosborne',
        createTs: '2024-02-24T23:30:10.354476',
        lastModifiedUserId: 'mosborne',
        lastModifiedTs: '2024-01-25T23:29:10.354476',
        replenRequestAdj: [
          {
            eventCd: 'RECEIVING',
            operation: 'ADD',
            adjQty: 10,
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
          },
        ],
        replenPlanMoves: [
          {
            replenPlanMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
            fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            fromLayoutName: 'FME0101W',
            fromParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            fromParentLayoutName: 'FER1',
            fromAnchorReceiptDate: '17-05-2024',
            fromAnchorExpiryDate: '17-05-2024',
            fromReceiptLabel: 'PT101023',
            toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
            toLayoutName: 'FME0101W',
            toParentLayoutDistinctName: 'SZ=FER1, ZN=FM',
            toParentLayoutName: 'FER1',
            totalReplenQty: 10,
            completedReplenQty: 10,
            statusCd: 'NEW',
            nextReplenRequestKey: 'RP1987000000009008',
            taskId: 'TM1987000000009008',
            createUserId: 'mosborne',
            createTs: '2024-01-25T23:29:10.354476',
            lastModifiedUserId: 'mosborne',
            lastModifiedTs: '2024-01-25T23:29:10.354476',
            replenPerformMoves: [
              {
                replenPerformMoveKey: 'dasasdakdjashjdfajfb9i3ueiuu3enn',
                userId: 'EFG',
                startTs: '2024-01-25T23:29:10.354476',
                endTs: '2024-01-25T23:29:10.354476',
                fromLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                fromLayoutName: 'FME0101W',
                fromReceiptLabel: 'PT101023',
                gatherQty: 10,
                statusCd: 'GATHER',
                toLayoutDistinctName: 'LC=FME0101W, SZ=FER1, ZN=FM',
                toLayoutName: 'FME0101W',
                toReceiptLabel: 'PT101023',
                deliverQty: 10,
                gatherSequence: 'GATHER',
                createUserId: 'mosborne',
                createTs: '2024-01-25T23:29:10.354476',
                lastModifiedUserId: 'mosborne',
                lastModifiedTs: '2024-01-25T23:29:10.354476',
              },
            ],
          },
        ],
      },
    },
  ],
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  first: true,
  last: true,
  numberOfElements: 0,
  empty: true,
};

const REPLENISHMENT_TYPES = [
  {
    label: 'ReplenishmentDashboard.Filter.AllReplenishments',
    value: REPLENISHMENT_STATUS.ALL,
  },
  {
    label: 'ReplenishmentDashboard.Filter.AssignedReplenishments',
    value: REPLENISHMENT_STATUS.ASSIGNED,
  },
  {
    label: 'ReplenishmentDashboard.Filter.UnassignedReplenishments',
    value: REPLENISHMENT_STATUS.UNASSIGNED,
  },
];
type ReplenishmentSortByDropdownContent = {
  label: string;
  value: REPLENISHMENT_SORT_BY;
};
const REPLENISHMENT_SORT_TYPES: ReplenishmentSortByDropdownContent[] = [
  {
    label: 'SortByOptions.SortByDateOldest',
    value: REPLENISHMENT_SORT_BY.DATE,
  },
  {
    label: 'SortByOptions.SortByOuts',
    value: REPLENISHMENT_SORT_BY.OUTS,
  },
  {
    label: 'SortByOptions.SortBySlotQty',
    value: REPLENISHMENT_SORT_BY.SLOT_QTY,
  },
  {
    label: 'SortByOptions.SortByPieces',
    value: REPLENISHMENT_SORT_BY.PIECES,
  },
  {
    label: 'SortByOptions.SortBySlot',
    value: REPLENISHMENT_SORT_BY.SLOT,
  },
  {
    label: 'SortByOptions.SortBySourceLocation',
    value: REPLENISHMENT_SORT_BY.SOURCE_LOCATION,
  },
];
const ALL_TIMES = [
  {
    label: 'ReplenishmentDashboard.Filter.AllTimes',
    value: REPLENISHMENT_TIME.ALL,
  },
  {
    label: 'ReplenishmentDashboard.Filter.Over24Hours',
    value: REPLENISHMENT_TIME.OVER24HRS,
  },
];

export const PO_DASHBOARD_SEARCH_BY_OPTIONS = [
  {
    label: 'PODashboard.SearchBy.NonFinalizedPONumber',
    value: PO_DASHBOARD_SEARCH_BY.NON_FINALIZED_PO_NUMBER,
  },
  {
    label: 'PODashboard.SearchBy.SKU',
    value: PO_DASHBOARD_SEARCH_BY.SKU,
  },
  {
    label: 'PODashboard.SearchBy.PartNumber',
    value: PO_DASHBOARD_SEARCH_BY.PART_NUMBER,
  },
  {
    label: 'PODashboard.SearchBy.VendorName',
    value: PO_DASHBOARD_SEARCH_BY.VENDOR_NAME,
  },
];

const PO_HISTORY_SEARCH_BY_OPTIONS = [
  {
    label: 'PODashboard.POHistory.SearchBy.FinalizedPONumber',
    value: PO_HISTORY_SEARCH_BY.FINALIZED_PO_NUMBER,
  },
  {
    label: 'PODashboard.POHistory.SearchBy.SKU',
    value: PO_HISTORY_SEARCH_BY.SKU,
  },
  {
    label: 'PODashboard.POHistory.SearchBy.PartNumber',
    value: PO_HISTORY_SEARCH_BY.PART_NUMBER,
  },
  {
    label: 'PODashboard.POHistory.SearchBy.VendorName',
    value: PO_HISTORY_SEARCH_BY.VENDOR_NAME,
  },
];

const RECOMMENDED_ASSIGNEES_DATA = [
  {
    userKey: '961744a753d1f95c7ee6d236dad0f9c5',
    userId: '11216557',
    employeeId: '1014',
    firstName: 'Vaibhav',
    middleName: 'Jain',
    secondLastName: 'Jain',
    lastName: 'Jain',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Wanwori IND',
    postalCd: '44000',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'Tech Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND',
            statusCd: 'ACTIVE',
            primaryFl: true,
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'FIRST',
            shiftDesc: 'First',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
  {
    userKey: '961744a753d1f95c7ee6d236dad0f8c6',
    userId: '11216566',
    employeeId: '1017',
    firstName: 'Shailesh',
    middleName: 'Valmik',
    secondLastName: 'Daund',
    lastName: 'Daund',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Wanwori IND',
    postalCd: '44000',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'Tech Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND',
            statusCd: 'ACTIVE',
            primaryFl: true,
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'SECOND',
            shiftDesc: 'SECOND',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
  {
    userKey: '961744a753d1f95c7ee6d236dad0f8c6',
    userId: '11231828',
    employeeId: '1018',
    firstName: 'Venkatesh',
    middleName: 'Narsing',
    secondLastName: 'Munasala',
    lastName: 'Munasala',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Pune IND',
    postalCd: '411033',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'UI Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND',
            statusCd: 'ACTIVE',
            primaryFl: true,
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'THIRD',
            shiftDesc: 'THIRD',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
  {
    userKey: '961744a753d1f95c7ee6d236dad0f8c6',
    userId: '11252887',
    employeeId: '1019',
    firstName: 'Vishal',
    middleName: 'Goyal',
    secondLastName: 'Goyal',
    lastName: 'Goyal',
    statusCd: 'ACTIVE',
    countryCd: 'IND',
    stateProvinceCd: 'MH',
    city: 'Pune',
    addressLine1: 'Banner',
    addressLine2: 'Pune IND',
    postalCd: '411033',
    hireDate: '0037-04-13',
    jobCd: 'Advisor',
    jobTitle: 'UI Lead',
    preferedLanguage: 'en_US',
    multilingualFl: true,
    temporaryFl: true,
    clockedInFl: null,
    facilities: [
      {
        facilityUserKey: 'faa35b894ad1463b699b031786ae921d',
        facilityId: '000000',
        activeFromDate: '2000-10-31',
        activeToDate: '2025-12-31',
        wageCd: 'Quaterly',
        statusCd: 'ACTIVE',
        primaryFl: true,
        departments: [
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'INBOUND', // current dept name
            statusCd: 'INACTIVE', // this dept status should be INACTIVE if its working in ANOTHER department
            primaryFl: true, // this key should be true for the current department
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
          {
            userDeptKey: '5cec362d83e847fb0229b1f12bf6c2a6',
            deptId: 'OUTBOUND', // other deptId name should be specific other dept name
            statusCd: 'ACTIVE', // it should have active here
            primaryFl: false, // this key should be false
            locale: 'en-US',
            deptDesc: 'Inbound-Receiving department',
          },
        ],
        shifts: [
          {
            shiftId: 'THIRD',
            shiftDesc: 'THIRD',
            shiftIndex: 1,
            locale: 'en-US',
            fromTime: '07:00',
            toTime: '17:00',
            dayOfWeek: 'MON',
          },
        ],
        roles: [
          {
            roleId: '25',
            locale: 'en-US',
            roleDesc: 'Advisor',
          },
        ],
      },
    ],
  },
];

const ACTIVITY_COMMENTS_DATA = [
  {
    comment: 'Looks good, thanks!',
    locale: 'en',
    timestamp: '2024-09-05T15:29:16+05:30',
    username: 'Charlie Dehesa',
  },
  {
    comment: 'Minor issue with login.',
    locale: 'en',
    timestamp: '2024-09-05T16:45:22+05:30',
    username: 'Joe Bell',
  },
  {
    comment: 'Great work on this!',
    locale: 'en',
    timestamp: '2024-09-05T17:32:45+05:30',
    username: 'Wes Dallas',
  },
  {
    comment: 'Can we review this?',
    locale: 'en',
    timestamp: '2024-09-05T18:15:30+05:30',
    username: 'Michael Osborne',
  },
];

const ADD_REPLENISHMENT_LOCATION_OPTIONS = [
  {
    label: 'PBE102A - SKU: 066332',
    value: 'value1',
  },
  {
    label: 'PBE115B - SKU: 076612',
    value: 'value2',
  },
  {
    label: 'PBE2456C - SKU: 000123',
    value: 'value3',
  },
];

const ADD_REPLENISHMENT_LOCATION_DATA = [
  { label: 'Vendor Name', text: 'Twintube Shock' },
  { label: 'Part #', text: '1234678' },
  { label: 'SKU', text: '066332' },
  { label: 'Suggested Vehicle', text: 'FML2102A' },
  { label: 'Slot Min / Max', text: 'FANY' },
  { label: 'Slot Quantity (Current)', text: '20 / 252' },
  { label: 'Suggested Quantity', text: '213' },
  { label: 'Pieces Avilable in Warehouse', text: '0' },
];

export {
  PO_DASHBOARD_ROWS,
  LOCATION_TYPES,
  ARRIVAL_TIMES,
  PO_CATEGORIES,
  SEARCH_PROPERTIES,
  DIVIDER_LABELS,
  TASK_SEARCH_MOCK_Data,
  REPLENISHMENT_DATA,
  REPLENISHMENT_TYPES,
  ALL_TIMES,
  PO_SHIFT,
  PO_HISTORY_SEARCH_BY_OPTIONS,
  REPLENISHMENT_SORT_TYPES,
  RECOMMENDED_ASSIGNEES_DATA,
  ACTIVITY_COMMENTS_DATA,
  ADD_REPLENISHMENT_LOCATION_OPTIONS,
  ADD_REPLENISHMENT_LOCATION_DATA,
};
