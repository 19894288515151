/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  CYCLE_COUNT_TABS,
  CYCLE_COUNT_TYPE_CD,
  HEADER_COMPONENT,
} from '@inventory/constants/constants';
import { InboundOrderDataType } from '@inventory/types/types';

export const getSubzone = (input: string) => {
  const match = input.match(/SZ=([^,]*)/);
  const szValue = match ? match[1] : null;
  return szValue;
};

export const getInboundOrderDataBySubzone = (
  inboundOrderData: InboundOrderDataType[],
  subzone: string
) =>
  inboundOrderData?.filter((item) =>
    item.inboundOrder.inboundOrderLines.some(
      (line) =>
        line.inboundOrderLineLocations &&
        line.inboundOrderLineLocations.some((location) => {
          if (getSubzone(location.layoutDistinctName) === subzone) {
            return true;
          } else {
            return false;
          }
        })
    )
  );

export const checkCountTypeCd = (CountTypeCdValue: string) => {
  const countTypeMap: { [key: string]: string } = {
    [CYCLE_COUNT_TABS.WALL_TO_WALL]: CYCLE_COUNT_TYPE_CD.WALL_TO_WALL,
    [CYCLE_COUNT_TABS.RISK_BASED]: CYCLE_COUNT_TYPE_CD.RBCC,
    [CYCLE_COUNT_TYPE_CD.WALL_TO_WALL]: HEADER_COMPONENT.WALL_TO_WALL,
    [CYCLE_COUNT_TYPE_CD.RBCC]: HEADER_COMPONENT.RISK_BASED,
  };

  return countTypeMap[CountTypeCdValue] || '';
};
