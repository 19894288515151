/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ColumnParam } from '@shared/components/Table/Table.types';

export const CYCLE_COUNTS_TABLE_COLUMNS: ColumnParam[] = [
  {
    label: 'CycleCount.TableColumns.CountName',
    id: 'countName',
    textAlign: 'start',
    width: 'calc(var(--st-unit-16) * 2.5)',
    isSorted: true,
  },

  {
    label: 'CycleCount.TableColumns.DateStarted',
    id: 'startedDate',
    textAlign: 'start',
    width: 'calc(var(--st-unit-16) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.TableColumns.DateClosed',
    id: 'closedDate',
    textAlign: 'start',
    width: 'calc(var(--st-unit-16) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.TableColumns.Assignees',
    id: 'users',
    textAlign: 'center',
    width: 'calc(var(--st-unit-14) * 2)',
    isSorted: false,
  },
  {
    label: 'CycleCount.TableColumns.NetCurrencyAccuracy',
    id: 'netAccuratePer',
    textAlign: 'end',
    width: 'calc(var(--st-unit-16) * 2)',
    isSorted: true,
  },
  {
    label: 'CycleCount.TableColumns.TotalCount',
    id: 'totalCountRevenue',
    headerCellTextAlign: 'center',
    textAlign: 'end',
    width: 'calc(var(--st-unit-15) * 2)',
    isSorted: true,
  },

  {
    label: 'CycleCount.TableColumns.NetCurrencyAdjustment',
    id: 'netAdjustmentRevenue',
    textAlign: 'end',
    width: 'calc(var(--st-unit-15) * 2.5)',
    isSorted: true,
  },
  {
    label: 'CycleCount.TableColumns.AbsPcAccuracy',
    id: 'absPcAdjustment',
    textAlign: 'end',
    width: 'calc(var(--st-unit-16) * 2.5)',
    isSorted: true,
  },
  {
    label: 'CycleCount.TableColumns.TotalPcCount',
    id: 'totalPcCount',
    textAlign: 'end',
    width: 'calc(var(--st-unit-16) * 2)',
    isSorted: true,
  },

  {
    label: 'CycleCount.TableColumns.AbsPcAdjustment',
    id: 'absPcPer',
    textAlign: 'end',
    width: 'calc(var(--st-unit-15) * 2.5)',
    isSorted: true,
  },
  {
    label: 'CycleCount.TableColumns.Progress',
    id: 'progress',
    textAlign: 'start',
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
  {
    label: 'CycleCount.TableColumns.Action',
    id: 'EndCountReason',
    textAlign: 'start',
    width: 'calc(var(--st-unit-10) * 2)',
    isSorted: false,
  },
];

export const SZ_ASSIGNED_USR_DRAWER_TABLE_COLUMN = [
  {
    id: 'location',
    label: 'SubZoneDrawerTableColumns.Location',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
  },
  {
    id: 'Assignee',
    label: '',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-6) * 2)',
  },
  {
    id: 'VarianceOver',
    label: 'SubZoneDrawerTableColumns.VarianceOver250',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-23) * 2)',
  },
  {
    id: 'AdjOver',
    label: 'SubZoneDrawerTableColumns.PcAdjOver250',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-19) * 2)',
  },
];
