/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMutation } from '@tanstack/react-query';
import { ReleaseOrderLaneRequest } from '@mdm/types/service.type.ts';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { releaseGroupService } from '@outbound/services/releaseGroupService.ts';

export const useMutateReleaseOrder = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    mutate: mutateReleaseOrder,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: ReleaseOrderLaneRequest) => {
      const payload = { ...params };
      return releaseGroupService.releaseOrder(payload);
    },
    onError: () => {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.LaneConfirmation.Description'));
    },
  });

  return { mutateReleaseOrder, isLoading, isError };
};
