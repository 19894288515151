/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import React, { lazy, startTransition, useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';

import { EMPTY_VALUE, USER_STATUS_CD } from '@shared/constants/constants';
import { StatusVariants } from '@shared/components/StatusBadge/StatusBadge.types';

import { generateDateString } from '@shared/utils/commonUtils';

import { MasterCard } from '@inbound/components/MasterCard/MasterCard';

const LazyLoadDetailDrawer = lazy(() =>
  import('@inbound/components/ReplenishmentDetailDrawer/ReplenishmentDetailDrawer').then(
    (module) => ({
      default: module.ReplenishmentDetailDrawer,
    })
  )
);

import * as T from './ReplenishmentDashboard.types';
import { Actionable } from '@az/starc-ui';
import { MAX_VISIBLE_AVATARS_REPLENISHMENT_DASHBOARD } from '@inbound/constants/constants';
import { ReplenishmentPayload } from '@inbound/components/ReplenishmentDetailDrawer/ReplenishmentDetailDrawer.types';
import { REPLENISHMENT_TYPES } from '@inbound/constants/dataConstants';
import { ReplenishmentUsersType } from '@inbound/types/types';

export const ReplenishmentDashboardCard: React.FC<T.ReplenishmentCardTypes> = ({
  data,
  replenishmentType,
  statusVariant,
}) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showCard, setShowCard] = useState<boolean>(true);
  const [assignedUsers, setAssignedUsers] = useState<ReplenishmentUsersType[]>([]);
  const [avatarData, setAvatarData] = useState<T.AvatarData[]>([]);

  const openDrawer = useCallback(() => {
    startTransition(() => setShowDrawer(true));
  }, []);

  const setCardStatus = useCallback(() => {
    if (
      replenishmentType === REPLENISHMENT_TYPES[1].value ||
      replenishmentType === REPLENISHMENT_TYPES[2].value
    ) {
      setShowCard(false);
    }
  }, [replenishmentType]);

  const onRemoveUser = useCallback(
    (id: string) => {
      setAvatarData((prevAvatarData) => prevAvatarData.filter((user) => user.id.toString() !== id));
      setAssignedUsers((prevAssignedUsers) =>
        prevAssignedUsers.filter((user) => user.userId.toString() !== id)
      );
      setCardStatus();
    },
    [setCardStatus]
  );

  const onAddUser = useCallback(
    (replenishmentData: ReplenishmentPayload) => {
      setAvatarData((prevUsers) => {
        const newUser = {
          id: parseInt(replenishmentData.replnAssignedUsers[0].userId, 10),
          firstName: replenishmentData.replnAssignedUsers[0].firstName,
          lastName: replenishmentData.replnAssignedUsers[0].lastName,
        };
        replenishmentData.replnAssignedUsers[0];

        const userExists = prevUsers.findIndex((user) => user.id === newUser.id);

        return userExists !== -1 ? prevUsers : [...prevUsers, newUser];
      });
      setAssignedUsers((prevUsers) => {
        const newUser = replenishmentData.replnAssignedUsers[0];

        const userExists = prevUsers
          .filter(
            (user) =>
              user.userStatusCd === USER_STATUS_CD.ASSIGNED ||
              user.userStatusCd === USER_STATUS_CD.INPROGRESS
          )
          .findIndex((user) => user.userId === newUser.userId);

        return userExists !== -1 ? prevUsers : [...prevUsers, newUser];
      });
      setCardStatus();
    },
    [setCardStatus]
  );

  const isAssignedUsers =
    assignedUsers.length > 0 &&
    assignedUsers.some(
      (user) =>
        user.userStatusCd === USER_STATUS_CD.ASSIGNED ||
        user.userStatusCd === USER_STATUS_CD.INPROGRESS
    );

  useEffect(() => {
    if (data.replnAssignedUsers) {
      const avatarDetails = data.replnAssignedUsers.filter(
        (user) =>
          user.userStatusCd === USER_STATUS_CD.ASSIGNED ||
          user.userStatusCd === USER_STATUS_CD.INPROGRESS
      );
      setAssignedUsers(avatarDetails);
      setAvatarData(
        avatarDetails.map((user) => ({
          id: parseInt(user.userId, 10),
          firstName: user.firstName || '',
          lastName: user.lastName || '',
        }))
      );
    } else {
      setAssignedUsers([]);
      setAvatarData([]);
    }
  }, [data.replnAssignedUsers]);

  useEffect(() => {
    if (replenishmentType) {
      setShowCard(true);
    }
  }, [replenishmentType]);

  return (
    <>
      {showCard && (
        <Actionable fullWidth={true} onClick={openDrawer}>
          <MasterCard
            title={data.replnRequest.productLongDesc ?? EMPTY_VALUE}
            replenPlanMoveKey={data.replnPlanMoveKey}
            statusBadgeProps={{
              variant: statusVariant,
              text: String(data.replnRequest.priority),
            }}
            label={t('ReplenishmentDashboard.Card.DateCreated', {
              date: data.replnRequest.srcRequestedTs
                ? generateDateString(
                    new Date(data.replnRequest.srcRequestedTs),
                    t('DateFormat.ShortTime')
                  )
                : EMPTY_VALUE,
            })}
            detailsData={{
              data: [
                {
                  label: t('ReplenishmentDashboard.Card.Pieces', {
                    pieces: data.totalReplnQty ?? EMPTY_VALUE,
                  }),
                },
                {
                  label: t('ReplenishmentDashboard.Card.SlotQty', {
                    slotQty: data.currentSlotQty ?? EMPTY_VALUE,
                  }),
                  color: data.currentSlotQty && +data.currentSlotQty <= 0 ? 'error' : undefined,
                },
                ...(statusVariant !== StatusVariants.NOT_STARTED
                  ? [
                      {
                        label: t('ReplenishmentDashboard.Card.Outs', {
                          outs: data.outsAmount ?? EMPTY_VALUE,
                        }),
                      },
                    ]
                  : []),
                {
                  label: t('ReplenishmentDashboard.Card.Subzone', {
                    subzone: data.replnRequest.parentLayoutName || EMPTY_VALUE,
                  }),
                },
                {
                  label: t('ReplenishmentDashboard.Card.Vehicle', {
                    vehicle: (data.replnAssets && data.replnAssets[0]?.assetCd) || EMPTY_VALUE,
                  }),
                },
                {
                  label: `${t('ReplenishmentDashboard.Card.SRC', {
                    src: data?.fromLayoutName || EMPTY_VALUE,
                  })} | ${data.replnRequest.layoutType}: ${data?.toLayoutName || EMPTY_VALUE}`,
                },
              ],
            }}
            {...(avatarData.length > 0
              ? {
                  avatarGroupData: {
                    users: avatarData,
                    size: 'small',
                    variant: 'assignee',
                    maxDisplay: MAX_VISIBLE_AVATARS_REPLENISHMENT_DASHBOARD,
                  },
                }
              : {})}
            topSectionDirection="column"
          />
        </Actionable>
      )}

      {showDrawer && (
        <LazyLoadDetailDrawer
          showDrawer={showDrawer}
          data={{ ...data, replnAssignedUsers: assignedUsers }}
          isAssigned={isAssignedUsers}
          statusVariant={statusVariant}
          onDrawerClose={() => setShowDrawer(!showDrawer)}
          onAddAssignee={(data) => {
            onAddUser(data);
          }}
          onRemoveAssignee={(id) => {
            onRemoveUser(id);
          }}
        />
      )}
    </>
  );
};
