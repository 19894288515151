/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const WAREHOUSE_CONFIGURATION_TABLE_ROWS = [
  {
    id: 1,
    dcNumber: 10,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 2,
    dcNumber: 15,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 3,
    dcNumber: 19,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '',
  },
  {
    id: 4,
    dcNumber: 22,
    address: '77 Rush St, Lexington, TN 38351, USA',
    description: 'Lexington Warehouse',
    contactNumber: '901-968-9190',
  },
  {
    id: 5,
    dcNumber: 25,
    address: '2110 Sonora Rd. Zanesville OH 43701, USA',
    description: 'Ohio Warehouse',
    contactNumber: '',
  },
];

const LOCATION_ASSOCIATED_SKU_ROWS = [
  {
    sku: '000000367',
    description: 'Shop Pro AF FS',
    partNumber: 'AXA0B3',
    quantity: '0',
  },
  {
    sku: '000000368',
    description: 'Shop Pro AF FS',
    partNumber: 'AXA0B4',
    quantity: '1',
  },
];

export { WAREHOUSE_CONFIGURATION_TABLE_ROWS, LOCATION_ASSOCIATED_SKU_ROWS };
