/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const FINALIZE_DASHBOARD_TABLE_COLUMNS = [
  {
    label: '',
    id: 'priority',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-15))',
    isSorted: false,
  },

  {
    label: 'FinalizationDashboard.Item.Vendor',
    id: 'vendorName',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.PONumber',
    id: 'poNumber',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.ArrivalTime',
    id: 'arrivalTime',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-18) * 2)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.POLocation',
    id: 'loation',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.ShippedPcs',
    id: 'shippedPcs',
    headerCellTextAlign: 'center' as const,
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },

  {
    label: 'FinalizationDashboard.Item.LinesWithError',
    id: 'linesWithError',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.POType',
    id: 'poType',
    textAlign: 'start' as const,
    width: 'var(--st-unit-20)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.Commodity',
    id: 'commodity',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },

  {
    label: 'FinalizationDashboard.Item.SentFromInbound',
    id: 'sentFromInbound',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: '',
    id: 'status',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
];
const FINALIZE_DASHBOARD_LOCATIONS_TABLE_COLUMNS = [
  {
    label: 'FinalizationDashboard.Item.Subzone',
    id: 'subzone',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.LocationType',
    id: 'locationType',
    textAlign: 'start' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'FinalizationDashboard.Item.TotalPOswithError',
    id: 'TotalPOswithError',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-20) * 3)',
    isSorted: true,
  },
  {
    label: 'FinalizationDashboard.Item.TotalLinesWithError',
    id: 'TotalLineswithError',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
  {
    label: '',
    id: 'loation',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
];

const TOP_VARIANCES_PRODUCT_CATEGORY_TABLE_COLUMNS = [
  {
    label: 'TopVariances.ProductCategory.Title',
    id: 'name',
    textAlign: 'center' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: false,
  },
  {
    label: 'TopVariances.ProductCategory.DollarVariances',
    id: 'value',
    textAlign: 'end' as const,
    width: 'calc(var(--st-unit-25) * 2)',
    isSorted: true,
  },
];

const INVENTORY_HISTORY_EVENT_TABLE_COLUMNS = [
  {
    label: 'InventoryHistoryDashboard.Item.Scope',
    id: 'scope',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'var(--st-unit-20)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.Bucket',
    id: 'bucket',
    headerCellTextAlign: 'center' as const,
    textAlign: 'center' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.Operation',
    id: 'operation',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'var(--st-unit-20)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.Quantity',
    id: 'qty',
    textAlign: 'center' as const,
    width: 'var(--st-unit-18)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.FinalQunatity',
    id: 'finalQty',
    textAlign: 'center' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.ReceiptLabelSequenceId',
    id: 'receiptLabelSequenceId',
    textAlign: 'center' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.CreateDate',
    id: 'createTs',
    textAlign: 'start' as const,
    width: 'var(--st-unit-32)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.Description',
    id: 'description',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 5)',
    isSorted: false,
  },
];

const INVENTORY_HISTORY_TABLE_COLUMNS = [
  {
    label: 'InventoryHistoryDashboard.Item.ProductId',
    id: 'productId',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20) * 2)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.LayoutName',
    id: 'layoutName',
    headerCellTextAlign: 'center' as const,
    textAlign: 'center' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.CreateDate',
    id: 'createDate',
    textAlign: 'start' as const,
    width: 'var(--st-unit-32)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.LayoutDistinctName',
    id: 'layoutDistinctName',
    headerCellTextAlign: 'center' as const,
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-32) * 2 + var(--st-unit-2))',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.ReceiptLabel',
    id: 'receiptLabel',
    textAlign: 'center' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.EventTypeCode',
    id: 'eventTypeCode',
    textAlign: 'center' as const,
    width: 'var(--st-unit-28)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.ReasonCod',
    id: 'reasonCod',
    textAlign: 'center' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.UOMCODE',
    id: 'uomCd',
    textAlign: 'start' as const,
    width: 'var(--st-unit-15)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.DocumentId',
    id: 'documentId',
    textAlign: 'start' as const,
    width: 'var(--st-unit-25)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.DocumentType',
    id: 'documentType',
    textAlign: 'center' as const,
    width: 'var(--st-unit-30)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.DocumentDate',
    id: 'documentDate',
    textAlign: 'start' as const,
    width: 'var(--st-unit-32)',
    isSorted: false,
  },
  {
    label: 'InventoryHistoryDashboard.Item.ExpiryDate',
    id: 'expiryDate',
    textAlign: 'start' as const,
    width: 'var(--st-unit-32)',
    isSorted: false,
  },
];

export {
  FINALIZE_DASHBOARD_TABLE_COLUMNS,
  FINALIZE_DASHBOARD_LOCATIONS_TABLE_COLUMNS,
  TOP_VARIANCES_PRODUCT_CATEGORY_TABLE_COLUMNS,
  INVENTORY_HISTORY_EVENT_TABLE_COLUMNS,
  INVENTORY_HISTORY_TABLE_COLUMNS,
};
