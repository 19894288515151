/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMutation } from '@tanstack/react-query';
import { RunReplenishmentParams } from '@mdm/types/service.type.ts';
import { NOTIFICATION_TYPES } from '@shared/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { releaseGroupService } from '@outbound/services/releaseGroupService.ts';

export const useMutateOrderReplenishment = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const {
    mutate: mutateReleaseGroups,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { releaseOrderReplenish: RunReplenishmentParams }) => {
      const payload = { ...params.releaseOrderReplenish };
      return releaseGroupService.runReplenishment(payload);
    },
    onError: () => {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.RunReplenishment.Description'));
    },
  });

  return { mutateReleaseGroups, isLoading, isError };
};
