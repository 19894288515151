/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AttributeSchema } from '@mdm/schemas/attributeSchema.ts';

export const ReleaseGroupListSchema = z.object({
  groupId: z.string(),
  groupName: z.string(),
  status: z.string(),
  groupType: z.string(),
  attributes: z.array(AttributeSchema),
});

export const ReleaseGroupLaneListSchema = z.object({
  layoutName: z.string(),
  layoutDistinctName: z.string(),
  lastAssignedFacilityId: z.string().nullable(),
});
