/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Text, View, classNames, Skeleton } from '@az/starc-ui';
import styles from '@shared/components/MasterTitle/MasterTitle.module.scss';

export const CycleCountDetailsHeaderSkeleton = () => {
  return (
    <View
      direction="row"
      align="center"
      wrap={false}
      padding={6}
      backgroundColor="secondary"
      width="100%"
      className={classNames(styles['master-title'])}
    >
      <View className={classNames(styles['master-title__data-container'])}>
        <View direction="column" className={styles['master-title__title-section']}>
          <Skeleton width="calc(var(--st-unit-24) * 2 + 2px)" height="var(--st-unit-5)" />

          <View direction="row" className={styles['master-title__title-wrapper']}>
            <Text
              size="175"
              weight="bold"
              as="h2"
              className={classNames(styles['master-title__title'])}
            >
              <Skeleton
                width="calc(var(--st-unit-23) * 4 - var(--st-unit-3) + 3px)"
                height="var(--st-unit-8)"
              />
            </Text>
            <View justify="center" className={styles['master-title__status-badge']}>
              <Skeleton width="calc(var(--st-unit-13) + 4px)" height="var(--st-unit-8)" />
            </View>
            <View className={styles['master-title__favorite_icon']}>
              <Skeleton borderRadius="small" width="var(--st-unit-6)" height="var(--st-unit-8)" />
            </View>
          </View>

          <Text
            color="500"
            size="100"
            weight="regular"
            className={styles['master-title__subtitle']}
          >
            <Skeleton width="calc(var(--st-unit-25) * 2 + 2px)" height="var(--st-unit-5)" />
          </Text>
        </View>

        <View direction="row" justify="end" align="center" gap={4}>
          <View.Item>
            <Skeleton borderRadius="small" width="var(--st-unit-32)" height="var(--st-unit-12)" />
          </View.Item>

          <View.Item>
            <View direction="row">
              <Skeleton borderRadius="small" width="var(--st-unit-6)" height="var(--st-unit-6)" />
            </View>
          </View.Item>
        </View>
      </View>
    </View>
  );
};
