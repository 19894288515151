/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { View, Button, Tooltip } from '@az/starc-ui';
import { formattedDate } from '@inventory/utils/topVarianceUtils';
import { TabsFilterDataProps, Default } from './CombinedPillTabs.types';
import styles from './CombinedPillTabs.module.scss';

export const CombinedPillTabs = <T extends Default>({
  TabsFilterData,
  selectedValue,
  onTabSelect,
}: TabsFilterDataProps<T>) => {
  const [selectedTab, setSelectedTab] = useState<T>(
    selectedValue ? selectedValue : TabsFilterData[0]
  );

  const onSelectFilter = (filter: T) => {
    setSelectedTab(filter);
    onTabSelect?.(filter);
  };

  return (
    <View
      gap={2}
      direction="row"
      align="center"
      attributes={{ 'data-testid': 'st-combined-pill-tabs' }}
    >
      {TabsFilterData.map((filter, index) => (
        <View.Item key={index}>
          {filter?.fromTs || filter?.toTs ? (
            <Tooltip
              variant="alt"
              triggerType="hover"
              bodyText={`${formattedDate(filter?.fromTs)} - ${formattedDate(filter?.toTs)}`}
              customTrigger={
                <Button
                  onClick={() => onSelectFilter(filter)}
                  variant={selectedTab?.value === filter.value ? 'pill' : 'pill-secondary'}
                  className={styles['combined-pill-tabs__button']}
                >
                  {filter.label}
                </Button>
              }
            />
          ) : (
            <Button
              onClick={() => onSelectFilter(filter)}
              variant={selectedTab?.value === filter.value ? 'pill' : 'pill-secondary'}
              className={styles['combined-pill-tabs__button']}
            >
              {filter.label}
            </Button>
          )}
        </View.Item>
      ))}
    </View>
  );
};
