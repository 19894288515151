/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { cycleCountsService } from '../cycleCountsService';
import {
  DownloadLocationCountSubzoneParams,
  GetLocationCountSearchParams,
  GetPhaseProgressParams,
} from '@inventory/types/service.types';

export const useCycleCount = (options: GetLocationCountSearchParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const {
    data: wallToWallData,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['wallToWall', JSON.stringify(options)],
    queryFn: async () => {
      const response = await cycleCountsService.getWallToWallData(options);
      return response;
    },
    enabled: isEnabled,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('CycleCount.Notification.Error'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { wallToWallData, isLoading, isFetching, isError, refetch };
};

export const useGetPhaseProgress = (options: GetPhaseProgressParams, isEnabled = true) => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const {
    data: data,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['cycleCountPhaseProgress', JSON.stringify(options)],
    queryFn: async () => {
      const response = await cycleCountsService.getPhaseProgress(options);
      return response;
    },
    enabled: isEnabled,
    staleTime: 0,
  });

  useEffect(() => {
    if (isError) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('CycleCount.Notification.Error'));
    }
  }, [isError, isEnabled, handleNotification, t]);

  return { data, isLoading, isFetching, isError, refetch };
};

export const useDownloadSubzones = (options: DownloadLocationCountSubzoneParams) => {
  const { isLoading, isError, isFetching, refetch } = useQuery({
    queryFn: () => cycleCountsService.downloadSubzones(options),
    enabled: false,
  });

  return { isLoading, isFetching, isError, refetch };
};
