/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Chat, Text, Tooltip, View } from '@az/starc-ui';

import { MAX_VISIBLE_AVATARS_CYCLE_COUNT } from '@inventory/constants/constants';
import {
  PAST_COUNT_MAP_COLUMN,
  PAST_COUNT_SORT_COLUMN,
} from '@inventory/pages/CycleCounts/components/PastCountTable/PastCountTable.constants';
import { CycleCountRowType, locationCountSearchType } from '@inventory/types/types';

import { AvatarGroup } from '@shared/components/AvatarGroup/AvatarGroup';
import { EMPTY_VALUE } from '@shared/constants/constants';
import {
  calculatePercentageWithSign,
  formatNumberWithCurrency,
  generateDateString,
} from '@shared/utils/commonUtils';
import { t } from 'i18next';

const generateCycleCountsTableCells = (record: CycleCountRowType) => {
  const {
    countName,
    startedDate,
    closedDate,
    users,
    netAccuratePer,
    totalCountRevenue,
    netAdjustmentRevenue,
    absPcAdjustment,
    totalPcCount,
    absPcPer,
    progress,
    endCountReason,
  } = record;
  return [
    {
      value: (
        <Text attributes={{ 'data-testvalue': countName }} weight="heavy">
          {countName}
        </Text>
      ),
      sortValue: countName,
    },
    {
      value: startedDate && generateDateString(new Date(startedDate), t('DateFormat.Long')),
      sortValue: startedDate && generateDateString(new Date(startedDate), t('DateFormat.Long')),
    },
    {
      value: closedDate && generateDateString(new Date(closedDate), t('DateFormat.Long')),
      sortValue: closedDate && generateDateString(new Date(closedDate), t('DateFormat.Long')),
    },
    {
      value:
        (users && (
          <View align="center">
            <AvatarGroup
              users={users}
              size="small"
              variant="assignee"
              maxDisplay={MAX_VISIBLE_AVATARS_CYCLE_COUNT}
            />
          </View>
        )) ||
        EMPTY_VALUE,
    },
    {
      value: calculatePercentageWithSign(netAccuratePer, 100) || EMPTY_VALUE,
      sortValue: netAccuratePer,
    },
    {
      value: formatNumberWithCurrency(totalCountRevenue),
      sortValue: totalCountRevenue,
    },
    {
      value: formatNumberWithCurrency(netAdjustmentRevenue),
      sortValue: netAdjustmentRevenue,
    },
    {
      value: calculatePercentageWithSign(absPcAdjustment, 100) || EMPTY_VALUE,
      sortValue: absPcAdjustment,
    },
    {
      value: totalPcCount,
      sortValue: totalPcCount,
    },
    {
      value: absPcPer,
      sortValue: absPcPer,
    },
    {
      value: calculatePercentageWithSign(progress, 100) || EMPTY_VALUE,
      sortValue: progress,
    },
    {
      value: endCountReason ? (
        <Tooltip triggerType="hover" svg={Chat} svgSize={6} bodyText={endCountReason} />
      ) : (
        EMPTY_VALUE
      ),
      sortValue: endCountReason,
    },
  ];
};

export const mapCycleCountRows = (records: CycleCountRowType[]) => {
  return records?.map((record: CycleCountRowType) => ({
    id: record.cycleCountId.toString(),
    cells: generateCycleCountsTableCells(record),
  }));
};

// Helper function to map wall to wall Active and Past Data using location count search API
export const mapCycleCountAPIData = (
  data: locationCountSearchType[] | undefined,
  statusFilter: string[]
): CycleCountRowType[] => {
  return (
    data
      ?.filter((rec: locationCountSearchType) => statusFilter.includes(rec.statusCd))
      .map((rec: locationCountSearchType) => ({
        cycleCountId: rec.taskId ? rec.taskId : EMPTY_VALUE,
        countName:
          rec.year && rec.phase ? `${rec.year} ${t('CycleCount.Phase')} ${rec.phase}` : EMPTY_VALUE,
        phase: rec.phase ? rec.phase : 0,
        startedDate: rec.startTs ? rec.startTs : '',
        closedDate: rec.endTs ? rec.endTs : '',
        status: rec.statusCd,
        users: rec.subzones
          ?.filter((subzone) => subzone.assignedUsers)
          .flatMap(
            (subzone) =>
              subzone.assignedUsers?.map((user) => ({
                id: Number(user.userId),
                firstName: user.firstName ? user.firstName : EMPTY_VALUE,
                lastName: user.lastName ? user.lastName : EMPTY_VALUE,
              })) || []
          ),
        netAccuratePer:
          rec.totalInvAmtCounted && rec.totalInvAmtExpected
            ? (rec.totalInvAmtCounted * 100) / rec.totalInvAmtExpected
            : 0,
        totalCountRevenue: rec.totalInvAmtCounted ? rec.totalInvAmtCounted : 0,
        netAdjustmentRevenue: rec.totalInvAmtAdjCalc ? rec.totalInvAmtAdjCalc : 0,
        absPcPer:
          rec.totalInvQtyCounted && rec.totalInvQtyExpected
            ? (rec.totalInvQtyCounted * 100) / rec.totalInvQtyExpected
            : 0,
        totalPcCount: rec.totalInvQtyCounted ? rec.totalInvQtyCounted : 0,
        absPcAdjustment: rec.totalInvQtyAdjustedCalc ? rec.totalInvQtyAdjustedCalc : 0,
        progress:
          rec.totalInvAmtCounted && rec.totalInvAmtExpected
            ? rec.totalInvAmtCounted / rec.totalInvAmtExpected
            : 0,
        endCountReason: rec.endReason || '',
      })) || []
  );
};

export const checkSortingColumn = (columnId: string) => {
  const columnMap: { [columnId: string]: string } = {
    [PAST_COUNT_MAP_COLUMN.COUNT_NAME]: PAST_COUNT_SORT_COLUMN.PHASE,
    [PAST_COUNT_MAP_COLUMN.STARTED_DATE]: PAST_COUNT_SORT_COLUMN.START_TS,
    [PAST_COUNT_MAP_COLUMN.CLOSED_DATE]: PAST_COUNT_SORT_COLUMN.END_TS,
    [PAST_COUNT_MAP_COLUMN.NET_ACCURATE_PER]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_COUNTED,
    [PAST_COUNT_MAP_COLUMN.TOTAL_COUNT_REVENUE]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_COUNTED,
    [PAST_COUNT_MAP_COLUMN.NET_ADJUSTMENT_REVENUE]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_ADJ_CALC,
    [PAST_COUNT_MAP_COLUMN.ABS_PC_PER]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_QTY_EXPECTED,
    [PAST_COUNT_MAP_COLUMN.TOTAL_PC_COUNT]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_QTY_COUNTED,
    [PAST_COUNT_MAP_COLUMN.ABS_PC_ADJUSTMENT]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_QTY_ADJUSTED_CALC,
    [PAST_COUNT_MAP_COLUMN.PROGRESS]: PAST_COUNT_SORT_COLUMN.TOTAL_INV_AMT_COUNTED,
    [PAST_COUNT_MAP_COLUMN.END_COUNT_REASON]: PAST_COUNT_SORT_COLUMN.END_REASON,
  };
  return columnMap[columnId] || '';
};
