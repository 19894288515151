/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const USE_REACT_DEV_TOOLS = !!window.env.REACT_DEV_TOOLS;
export const USE_JOTAI_DEV = !!window.env.DEBUG_JOTAI;

export const BFF_URL = window.env.BFF_URL;

export const FRONTEND_BASE_URL = window.env.BASE_URL;

export const INBOUND_RECEIVING_COMMAND_HANDLER_URL =
  window.env.INBOUND_RECEIVING_COMMAND_HANDLER_URL;
export const INBOUND_RECEIVING_QUERY_HANDLER_URL = window.env.INBOUND_RECEIVING_QUERY_HANDLER_URL;

export const FACILITY_CONFIG_QUERY_HANDLER_URL = window.env.FACILITY_CONFIG_QUERY_HANDLER_URL;
export const FACILITY_CONFIG_COMMAND_HANDLER_URL = window.env.FACILITY_CONFIG_COMMAND_HANDLER_URL;

export const LAYOUT_COMMAND_HANDLER_URL = window.env.LAYOUT_COMMAND_HANDLER_URL;
export const LAYOUT_QUERY_HANDLER_URL = window.env.LAYOUT_QUERY_HANDLER_URL;

export const ASSET_COMMAND_HANDLER_URL = window.env.ASSET_COMMAND_HANDLER_URL;
export const ASSET_QUERY_HANDLER_URL = window.env.ASSET_QUERY_HANDLER_URL;

export const USER_QUERY_HANDLER_URL = window.env.USER_QUERY_HANDLER_URL;
export const USER_COMMAND_HANDLER_URL = window.env.USER_COMMAND_HANDLER_URL;

export const PRODUCT_QUERY_HANDLER_URL = window.env.PRODUCT_QUERY_HANDLER_URL;
export const PRODUCT_COMMAND_HANDLER_URL = window.env.PRODUCT_COMMAND_HANDLER_URL;
export const REPLENISHMENT_QUERY_HANDLER_URL = window.env.REPLENISHMENT_QUERY_HANDLER_URL;
export const REPLENISHMENT_COMMAND_HANDLER_URL = window.env.REPLENISHMENT_COMMAND_HANDLER_URL;

export const INVENTORY_QUERY_HANDLER_URL = window.env.INVENTORY_QUERY_HANDLER_URL;
export const VENDOR_QUERY_HANDLER_URL = window.env.VENDOR_QUERY_HANDLER_URL;

export const PING_REDIRECT_URL = `${FRONTEND_BASE_URL}/auth/callback`;
export const PING_CLIENT_ID = window.env.PING_CLIENT_ID;
export const PING_FEDERATE_RUNTIME_URL = window.env.PING_FEDERATE_RUNTIME_URL;
export const TASK_QUERY_HANDLER_URL = window.env.TASKS_QUERY_HANDLER_URL;
export const TASK_COMMAND_HANDLER_URL = window.env.TASK_COMMAND_HANDLER_URL;
export const LOCATION_COUNT_QUERY_SERVICE_URL = window.env.LOCATION_COUNT_QUERY_SERVICE_URL;
export const LOCATION_COUNT_COMMAND_HANDLER_URL = window.env.LOCATION_COUNT_COMMAND_HANDLER_URL;
export const LAYOUT_QUERY_SERVICE_URL = window.env.LAYOUT_QUERY_SERVICE_URL;
export const ORDER_RELEASE_QUERY_HANDLER_URL = window.env.ORDER_RELEASE_QUERY_HANDLER_URL;
export const ORDER_RELEASE_COMMAND_HANDLER_URL = window.env.ORDER_RELEASE_COMMAND_HANDLER_URL;
