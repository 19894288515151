/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';

import { useGetPhaseProgress } from '@inventory/services/hooks/useCycleCounts';
import { fiscalCalendarInfoAtom } from '@inventory/atoms/fiscalCalendarInfo/fiscalCalendarInfo';
import { CYCLE_COUNT_TYPE_CD, COUNT_CALENDAR } from '@inventory/constants/constants';

export const useGetFiscalCalendarInfo = () => {
  const [fiscalCalendarInfo, setFiscalCalendarInfo] = useAtom(fiscalCalendarInfoAtom);

  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();

  const { isLoading, refetch } = useGetPhaseProgress(
    {
      // `countTypeCd` is required by the API but does not affect the fiscal calendar data being fetched.
      // Passing `RBCC` as a placeholder since it's mandatory.
      countTypeCd: CYCLE_COUNT_TYPE_CD.RBCC,
      metrics: [COUNT_CALENDAR],
    },
    false
  );

  useEffect(() => {
    if (!fiscalCalendarInfo) {
      refetch()
        .then(({ data }) => {
          if (data?.countCalendar) {
            setFiscalCalendarInfo(data.countCalendar);
          }
          return;
        })
        .catch(() => {
          handleNotification(NOTIFICATION_TYPES.ERROR, t('Errors.FiscalCalendar.Description'));
        });
    }
  }, [fiscalCalendarInfo, handleNotification, refetch, setFiscalCalendarInfo, t]);

  return {
    fiscalCalendarInfo: fiscalCalendarInfo,
    isLoading,
  };
};
