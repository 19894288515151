/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Button, Divider, Text, View } from '@az/starc-ui';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { MasterTitle } from '@shared/components/MasterTitle/MasterTitle.tsx';
import { PAGE_URLS } from '@shared/constants/routes.ts';

import { useNavigate, useParams } from 'react-router-dom';

import { z } from 'zod';

import styles from './ProductDetail.module.scss';
import { useBreadcrumb } from '@mdm/hooks/useBreadcrumb.ts';
import { getFormDefaults } from '@mdm/utils/form/formUtils.tsx';

import {
  ENTITY_ASSOCIATIONS,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  PRODUCT_ID_TYPE,
} from '@shared/constants/constants.ts';
import { useGetWarehouseProducts } from '@shared/services/hooks/useGetWarehouseProducts.ts';
import { WarehouseProductViewSchema } from '@shared/schemas/warehouseProductSchema.ts';
import { useEffect, useState } from 'react';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { DetailsSectionSkeleton } from '@shared/components/Skeletons/DetailsSectionSkeleton.tsx';
import { useMutateWarehouseProduct } from '@shared/services/hooks/useMutateWarehouseProduct.ts';
import { useQueryClient } from '@tanstack/react-query';
import { DIRECTION as TABLE_SORT_DIRECTION } from '@shared/components/Table/Table.types.ts';
import { ProductAlias } from '@mdm/pages/RDM/ProductManager/Product/Detail/ProductAlias.tsx';
import { ProductVolumetrics } from '@mdm/pages/RDM/ProductManager/Product/Detail/ProductVolumetrics.tsx';
import { ProductDetailForm } from '@mdm/pages/RDM/ProductManager/Product/Detail/ProductDetailForm.tsx';

export const ProductDetail = () => {
  /* Constants */
  type FormData = z.infer<typeof WarehouseProductViewSchema>;
  const { productId } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(!productId);
  const { isLoading: isWarehouseProductUpdating, mutateWarehouseProduct } =
    useMutateWarehouseProduct();

  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const hookFormMethods = useForm<FormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getFormDefaults(WarehouseProductViewSchema),
    resolver: zodResolver(WarehouseProductViewSchema),
  });

  const { handleSubmit, reset: productReset, getValues } = hookFormMethods;

  const { productsSearchData } = useGetWarehouseProducts(
    {
      productIdType: PRODUCT_ID_TYPE.PRODUCT_ID,
      products: [productId || ''],
      currentPage: 0,
      pageSize: PAGE_SIZE,
      sortBy: 'productId',
      direction: TABLE_SORT_DIRECTION.ASCENDING,
      entityAssociations: [
        ENTITY_ASSOCIATIONS.CATEGORY,
        ENTITY_ASSOCIATIONS.SAFETY,
        ENTITY_ASSOCIATIONS.PRODUCT_CODES,
        ENTITY_ASSOCIATIONS.PHYSICAL_ATTRIBUTE,
        ENTITY_ASSOCIATIONS.PHYSICAL_PACKING_UNITS,
        ENTITY_ASSOCIATIONS.WHSE_UOM_CONVERSION,
        ENTITY_ASSOCIATIONS.WHSE_ATTRIBS,
      ],
    },
    !!productId
  );

  /* Function */
  const onSubmit = () => {
    const payload = getValues();

    if (productId) {
      const loadUnitCapacityQty =
        payload.productFacilityAttributes?.loadUnitCapacityQty ||
        payload.productFacilityAttributes?.loadUnitCapacityQty === 0
          ? payload.productFacilityAttributes?.loadUnitCapacityQty
          : null;

      mutateWarehouseProduct(
        {
          productId,
          warehouseRequestType: {
            uomConversion: {
              minOrderQty: payload.uomConversion?.minOrderQty || 0,
              maxOrderQty: payload.uomConversion?.maxOrderQty || 0,
              whseInnerQty: payload.uomConversion?.whseInnerQty || 0,
              whseOuterQty: payload.uomConversion?.whseOuterQty || 0,
              conversionRatio: payload.uomConversion?.conversionRatio || 0,
              whseCalculatedInnerQty: payload.uomConversion?.whseCalculatedInnerQty || 0,
            },
            productFacilityAttributes: {
              maxLanesQty: payload.productFacilityAttributes?.maxLanesQty || 0,
              maxStackingQty: payload.productFacilityAttributes?.maxLanesQty || 0,
              isCubescannedFl: payload.productFacilityAttributes?.isCubescannedFl || 'false',
              toteCompartmentSizeCd:
                payload.productFacilityAttributes?.toteCompartmentSizeCd || null,
              loadUnitCapacityQty:
                typeof loadUnitCapacityQty === 'string'
                  ? parseFloat(loadUnitCapacityQty)
                  : loadUnitCapacityQty,
              currentBaseCostAmt: payload.productFacilityAttributes?.currentBaseCostAmt || 0,
              currentCoreCostAmt: payload.productFacilityAttributes?.currentCoreCostAmt || 0,
            },
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['products']);
            const successMessage = t('Success.Action.Product.Updated', {
              productId: productId,
            });

            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
          },
        }
      );
    }
  };

  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb(productId ? PAGE_URLS.PRODUCT_CREATE : '');

  useEffect(() => {
    if (productsSearchData && productsSearchData.content[0]) {
      productReset({
        ...productsSearchData.content[0],
      });

      setIsDataLoaded(true);
    } else if (productId && productsSearchData && productsSearchData.content.length === 0) {
      handleNotification(NOTIFICATION_TYPES.ERROR, t('ZoneNotFound'));
    }
  }, [handleNotification, productId, t, productsSearchData, productReset]);

  useEffect(() => {
    document.body.setAttribute('data-page-background', 'white');

    return () => {
      document.body.setAttribute('data-page-background', '');
    };
  }, []);

  if (!isDataLoaded) {
    return <DetailsSectionSkeleton items={10} />;
  } else {
    return (
      <>
        <View
          className={styles['product-detail']}
          backgroundColor="secondary"
          direction="column"
          height="100%"
        >
          <View.Item
            attributes={{
              'data-testid': 'product-details-header',
            }}
          >
            <MasterTitle
              title={`${t('ProductDetails.Sku.Label')}: ${productId ? productId : t('Untitled')}`}
              breadcrumbProps={breadcrumbs}
            >
              <View
                attributes={{
                  'data-testid': 'product-details-action',
                }}
                direction="row"
                justify="end"
                align="center"
                gap={4}
              >
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button variant="secondary" size="large" onClick={() => navigate(-1)}>
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('Cancel')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
                <View.Item>
                  <View direction="row" gap={4}>
                    <View.Item>
                      <Button
                        size="large"
                        loading={isWarehouseProductUpdating}
                        onClick={() => handleSubmit(onSubmit)()}
                      >
                        <View direction="row" align="center" justify="center" gap={2}>
                          <Text>{t('SaveEdits')}</Text>
                        </View>
                      </Button>
                    </View.Item>
                  </View>
                </View.Item>
              </View>
            </MasterTitle>
          </View.Item>
          <View backgroundColor="secondary" padding={6} height="100%">
            <View>
              <View.Item>
                <Text size="125" weight="bold">
                  {t('ProductDetails.Title')}
                </Text>
              </View.Item>
            </View>
            <View className={styles['product-detail__content-section']}>
              <View>
                <form className="product-detail__form">
                  <FormProvider {...hookFormMethods}>
                    <ProductDetailForm />
                  </FormProvider>

                  <View padding={[4, 0, 0, 0]}>
                    <Divider />
                  </View>

                  <FormProvider {...hookFormMethods}>
                    <ProductAlias />
                  </FormProvider>

                  <View padding={[4, 0, 0, 0]}>
                    <Divider />
                  </View>

                  <FormProvider {...hookFormMethods}>
                    <ProductVolumetrics />
                  </FormProvider>
                </form>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
};
