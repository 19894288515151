/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { ActiveCountTable } from '../ActiveCountTable/ActiveCountTable';
import { PastCountTable } from '../PastCountTable/PastCountTable';
import { CYCLE_COUNT_TABS } from '@inventory/constants/constants';

export const WallToWall = () => {
  return (
    <View divided padding={6} gap={6}>
      <ActiveCountTable countTypeCd={CYCLE_COUNT_TABS.WALL_TO_WALL} />
      <PastCountTable countTypeCd={CYCLE_COUNT_TABS.WALL_TO_WALL} />
    </View>
  );
};
