/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Skeleton } from '@az/starc-ui';
import styles from '@shared/components/MasterTitle/MasterTitle.module.scss';

export const AccuracyMeasurementsCardSkeleton = () => {
  return (
    <View direction="column" padding={[6, 0]}>
      <View direction="column">
        <View>
          <Skeleton width="50%" height="var(--st-unit-4)" />
        </View>
      </View>

      <View direction="row" padding={[4, 0]} gap={2} width="100%" align="center">
        <View.Item grow>
          <Skeleton width="100%" height="var(--st-unit-13)" />
        </View.Item>
        <View.Item grow>
          <Skeleton width="100%" height="var(--st-unit-13)" />
        </View.Item>
      </View>

      <View direction="row" gap={2} width="100%" align="center">
        <View.Item grow className={styles['gray-card']}>
          <Skeleton width="100%" height="var(--st-unit-13)" />
        </View.Item>
        <View.Item grow className={styles['gray-card']}>
          <Skeleton width="100%" height="var(--st-unit-13)" />
        </View.Item>
        <View.Item grow className={styles['gray-card']}>
          <Skeleton width="100%" height="var(--st-unit-13)" />
        </View.Item>
      </View>
    </View>
  );
};
