/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { atom } from 'jotai';
import type { FiscalCalendarInfo } from '@inventory/types/types';

export const fiscalCalendarInfoAtom = atom<FiscalCalendarInfo | null>(null);
