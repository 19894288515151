/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ReleaseGroup } from '@outbound/components/ReleaseGroupAccordion/ReleaseGroupAccordion.types.tsx';
import { ChangeEvent } from 'react';
import { t } from 'i18next';
import {
  RELEASE_ORDER_FIELD,
  RELEASE_ORDER_STATUS,
  RELEASE_ORDER_STATUS_COLOR_CODE,
} from '@outbound/constants/constants.ts';
import { Button, Checkbox, Select, Text, View } from '@az/starc-ui';
import { ActionMenu } from '@mdm/components/ActionMenu/ActionMenu.tsx';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { ReleaseOrderLane } from '@outbound/pages/ControlDesk/ReleaseOrders/LaneConfirmation/LaneConfirmationAccordion/LaneConfirmationAccordion.types.tsx';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge.tsx';

export const mapDifOrderReleaseByAreaTableRows = (
  rows: ReleaseGroup[],
  handleCheck: (e: ChangeEvent<HTMLInputElement>) => void,
  onRunReplenishmentClick: (releaseGroup: ReleaseGroup) => void,
  isReplenshimentRunning: boolean
) => {
  return rows.map((releaseGroup) => {
    const actionMenus = [
      {
        label: t('OutboundMatrix.MenuOptions.ReleaseToPaper'),
        clickHandler: () => ({}),
      },
      {
        label: t('OutboundMatrix.MenuOptions.CloseToPaper'),
        clickHandler: () => ({}),
      },
    ];

    const countOfSkus = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.SKU_COUNT);

    const totalPallets = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PALLETS);

    const totalPieces = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PIECES);

    return {
      id: releaseGroup.groupId.toString(),
      cells: [
        {
          value: (
            <Checkbox
              name="area-select"
              value={releaseGroup.groupId}
              disabled={releaseGroup.status === RELEASE_ORDER_STATUS.RELEASED}
              onChange={handleCheck}
            />
          ),
          sortValue: '',
        },
        { value: releaseGroup.groupName, sortValue: releaseGroup.groupName },
        { value: countOfSkus, sortValue: countOfSkus },
        { value: totalPallets, sortValue: totalPallets },
        { value: totalPieces, sortValue: totalPieces },
        {
          value: (
            <View direction="row" gap={2} align="center">
              {releaseGroup.status === RELEASE_ORDER_STATUS.CREATED ? (
                <Button
                  variant="secondary"
                  loading={isReplenshimentRunning}
                  onClick={() => onRunReplenishmentClick(releaseGroup)}
                >
                  <Text size="100">{t('OutboundMatrix.OrderRelease.RunReplenishment')}</Text>
                </Button>
              ) : (
                <View height="100%">
                  <StatusBadge
                    variant={
                      RELEASE_ORDER_STATUS_COLOR_CODE[
                        releaseGroup.status as keyof typeof RELEASE_ORDER_STATUS_COLOR_CODE
                      ]
                    }
                    text={releaseGroup.status.replace(/_/g, ' ')}
                  />
                </View>
              )}
              <ActionMenu menus={actionMenus} />
            </View>
          ),
          sortValue: '',
        },
      ],
    };
  });
};

export const mapDifLaneConfirmationTableRows = (
  rows: ReleaseGroup[],
  laneOptions: SelectOption[],
  selectedReleaseOrderLane: ReleaseOrderLane[],
  handleLaneChange: (releaseGroupId: string, laneId: string) => void
) => {
  return rows.map((releaseGroup) => {
    const countOfArea = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.AREA_COUNT);

    const totalPallets = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PALLETS);

    const totalPieces = getAttributeValue(releaseGroup.attributes, RELEASE_ORDER_FIELD.PIECES);

    return {
      id: releaseGroup.groupId.toString(),
      cells: [
        { value: releaseGroup.groupName, sortValue: releaseGroup.groupName },
        { value: countOfArea, sortValue: countOfArea },
        { value: totalPallets, sortValue: totalPallets },
        { value: totalPieces, sortValue: totalPieces },
        {
          value: (
            <View>
              <Select
                label=""
                options={laneOptions}
                value={{
                  label:
                    laneOptions.find(
                      (lane) =>
                        lane.value ===
                        selectedReleaseOrderLane.find(
                          (lane) => lane.groupId === releaseGroup.groupId
                        )?.laneId
                    )?.label || '',
                  value:
                    selectedReleaseOrderLane.find((lane) => lane.groupId === releaseGroup.groupId)
                      ?.laneId || '',
                }}
                onValueChange={(e) => handleLaneChange(releaseGroup.groupId, e?.value || '')}
              />
            </View>
          ),
          sortValue: '',
        },
      ],
    };
  });
};
