/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { t } from 'i18next';

import { Text, View, Icon, Actionable } from '@az/starc-ui';

import { Accordion } from '@shared/components/Accordion/Accordion';

import { Props } from './LaneConfirmationAccordion.types.tsx';
import Styles from './LaneConfirmationAccordion.module.scss';
import { ChevronDown, ChevronUp } from '@az/starc-ui-icons';
import {
  RELEASE_ORDER_FIELD,
  RELEASE_ORDER_STATUS,
  RELEASE_ORDER_STATUS_COLOR_CODE,
} from '@outbound/constants/constants.ts';
import { StatusBadge } from '@shared/components/StatusBadge/StatusBadge.tsx';
import { getAttributeValue } from '@shared/utils/commonUtils.ts';
import { LaneConfirmationList } from '@outbound/pages/ControlDesk/ReleaseOrders/LaneConfirmation/LaneConfirmationAccordion/LaneConfirmationList.tsx';

export const LaneConfirmationAccordion = ({ releaseGroup, orderType }: Props) => {
  /* State variables */
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  /* variables */
  const { attributes, groupName, status } = releaseGroup;

  /* Functions */
  const handleAccordionClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <>
      <View className={[Styles['lane-confirmation-accordion']]}>
        <Accordion
          open={isAccordionOpen}
          header={{
            label: (
              <View direction="row" align="center">
                <View
                  direction="row"
                  align="center"
                  className={Styles['lane-confirmation-accordion__left']}
                >
                  <View
                    direction="row"
                    align="center"
                    padding={[4, 22, 4, 0]}
                    className={Styles['lane-confirmation-accordion__left--label']}
                  >
                    <Text size="100" weight="bold">
                      {groupName}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.OrderCount', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.ORDER_COUNT),
                      })}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pallet', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PALLETS),
                      })}
                    </Text>
                  </View>
                  <View padding={[4, 10, 4, 2]}>
                    <Text size="100" weight="regular">
                      {t('OutboundMatrix.OrderRelease.Pieces', {
                        count: getAttributeValue(attributes, RELEASE_ORDER_FIELD.PIECES),
                      })}
                    </Text>
                  </View>
                </View>
              </View>
            ),
            auxiliaryLabel: (
              <View
                direction="row"
                align="center"
                className={Styles['lane-confirmation-accordion__right']}
              >
                {status !== RELEASE_ORDER_STATUS.CREATED && (
                  <View height="100%">
                    <StatusBadge
                      variant={
                        RELEASE_ORDER_STATUS_COLOR_CODE[
                          status as keyof typeof RELEASE_ORDER_STATUS_COLOR_CODE
                        ]
                      }
                      text={status.replace(/_/g, ' ')}
                    />
                  </View>
                )}
                <Actionable onClick={handleAccordionClick}>
                  <Icon size={6} svg={isAccordionOpen ? ChevronUp : ChevronDown} />
                </Actionable>
              </View>
            ),
          }}
        >
          <LaneConfirmationList releaseGroup={releaseGroup} orderType={orderType} />
        </Accordion>
      </View>
    </>
  );
};
