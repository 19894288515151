/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@taskManagement/types/service.type';

import { PaginatedResponseSchema } from '@taskManagement/schemas/paginatedResponseSchema';
import { LogError } from '@ofm/classes/LogError.ts';
import { ErrorLogType } from '@ofm/constants/constants.ts';
import { formatErrorLog } from '@ofm/utils/utils.ts';
import { AttributeType } from '@taskManagement/types/types';

export interface FilterAttributeType {
  key: string;
  value: string | null;
}

export const parseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): z.infer<T> | undefined => {
  const parsedData = schema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as z.infer<typeof schema> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // console.log('ZOD ERROR', parsedData);
    }
    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

export const paginatedParseAndLog = <T extends z.ZodTypeAny>(
  schema: T,
  data: AxiosResponse
): PaginatedResponse<T> | undefined => {
  const paginatedSchema = PaginatedResponseSchema(schema);
  const parsedData = paginatedSchema.safeParse(data);

  if (parsedData.success) {
    return parsedData.data as unknown as PaginatedResponse<T> | undefined;
  } else {
    if (import.meta.env.MODE === 'development') {
      // Leaving this here to facilitate debugging locally
      // console.log('ZOD ERROR', parsedData);
    }

    throw new LogError(formatErrorLog(ErrorLogType.ZOD, parsedData.error));
  }
};

/* Check Task Search API response attribute key values */
export const getAttributeValueByKey = (attributes: AttributeType[], key: string): string => {
  if (!attributes || !Array.isArray(attributes) || attributes.length === 0) {
    return '';
  }

  const attribute = attributes.find((attr) => attr.key === key);
  return attribute ? attribute.value : '';
};

/* task update attribute payload function */
export function updateTaskAttributes(
  attributes: AttributeType[],
  keys: string[]
): FilterAttributeType[] {
  return keys
    .map((key) => {
      const attribute = attributes.find((item) => item.key === key);
      return attribute
        ? {
            key,
            value: attribute.value,
          }
        : null;
    })
    .filter((item) => item !== null) as FilterAttributeType[];
}

export const padZerotoTime = (value: string): string => {
  if (value.length < 2) return value.padStart(2, '0');
  return value;
};
