/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useTranslation } from 'react-i18next';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubZoneAssigneesLocCountSyncParams } from '@inventory/types/service.types';
import { createLocationCountService } from '../createLocationCountService';
import { ErrorResponse } from 'react-router-dom';
import { NOTIFICATION_TYPES } from '@shared/constants/constants';

// using location-counts/perform-action-sync API
export const useMutateSubZoneAssignees = () => {
  const { t } = useTranslation();
  const { handleNotification } = useNotificationHandler();
  const queryClient = useQueryClient();
  let message = t('Errors.Action.UpdateSubZoneAssignee');

  const {
    mutate: mutateSubZoneAssignees,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (params: { AssignSubZoneLocCountRequest: SubZoneAssigneesLocCountSyncParams }) => {
      const payload = params.AssignSubZoneLocCountRequest;
      return createLocationCountService.UpdateSubZoneAssigneesLocCount(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['subzoneList']);
    },
    onError: (error: ErrorResponse) => {
      if (error?.data) {
        message = error.data;
      }
      handleNotification(NOTIFICATION_TYPES.ERROR, message);
    },
  });

  return { mutateSubZoneAssignees, isLoading, isError };
};
