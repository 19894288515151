/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ChangeEvent } from 'react';
import { Item } from '@outbound/components/OrderReleaseAccordion/OrderReleaseAccordion.types.tsx';
import { Store } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/NewStoreOrders/NewStore.types.tsx';

export const getStoreGroup = (storeNumber: string, groupedOrders: string[][]): string[] => {
  const storeGroup = groupedOrders?.find((subGroup) => subGroup.includes(storeNumber));
  return storeGroup ? storeGroup : [];
};

export const handleReleaseOrderCheck = (
  e: ChangeEvent<HTMLInputElement>,
  selectedOrders: string[],
  setSelectedOrders: (orders: string[]) => void,
  groupedOrders: string[][],
  selectedItem: Item | Store | null,
  groupId: string,
  selectedRootReleaseOrders: string[],
  setSelectedRootReleaseOrders: (rootGroups: string[]) => void,
  rootGroupId: string
) => {
  const { value, checked } = e.target;
  const storeGroup = getStoreGroup(value, groupedOrders);

  if (!selectedItem || selectedItem?.id === groupId) {
    if (checked) {
      setSelectedRootReleaseOrders([...new Set([...selectedRootReleaseOrders, rootGroupId])]);
      setSelectedOrders([
        ...new Set([...selectedOrders, ...(storeGroup.length ? storeGroup : [value])]),
      ]);
    } else {
      setSelectedOrders(
        selectedOrders.filter((id) => (storeGroup.length ? !storeGroup.includes(id) : id !== value))
      );
    }
  } else {
    if (checked) {
      setSelectedRootReleaseOrders([...new Set([...selectedRootReleaseOrders, rootGroupId])]);
      setSelectedOrders([...new Set([...(storeGroup.length ? storeGroup : [value])])]);
    }
  }
};
