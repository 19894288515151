/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const PaginatedResponseSchema = <T extends z.ZodTypeAny>(dataSchema: T) => {
  return z.object({
    content: z.array(dataSchema),
    empty: z.boolean(),
    first: z.boolean(),
    last: z.boolean(),
    number: z.number(),
    numberOfElements: z.number(),
    pageable: z.object({
      offset: z.number(),
      pageNumber: z.number().optional(),
      pageSize: z.number(),
      paged: z.boolean(),
      sort: z
        .object({
          empty: z.boolean().optional(),
          sorted: z.boolean().optional(),
          unsorted: z.boolean().optional(),
          direction: z.string().nullable().optional(),
          sortBy: z.string().nullable().optional(),
        })
        .optional(),
      unpaged: z.boolean().optional(),
    }),
    sort: z
      .object({
        empty: z.boolean().optional(),
        sorted: z.boolean().optional(),
        unsorted: z.boolean().optional(),
        direction: z.string().nullable().optional(),
        sortBy: z.string().nullable().optional(),
      })
      .optional(),
    size: z.number(),
    totalElements: z.number(),
    totalPages: z.number(),
  });
};
