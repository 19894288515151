/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { View } from '@az/starc-ui';
import { DownloadProvider } from '@shared/hooks/useDownload';

import { CycleCountDetailsHeader } from './CycleCountDetailsComponents/CycleCountDetailsHeader/CycleCountDetailsHeader';
import { CycleCountDetailsTabs } from './CycleCountDetailsComponents/CycleCountDetailsTabs/CycleCountDetailsTabs';
import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import {
  CYCLE_COUNT_ENTITY_ASSOCIATION,
  PAGE,
  SINGLE_PAGE_SIZE,
} from '@inventory/constants/constants';
import { SORT_BY, SORT_ORDER } from '@shared/constants/constants';
import { checkCountTypeCd } from '@inventory/utils/utils';
import { CycleCountDetailsHeaderSkeleton } from './CycleCountDetailsComponents/CycleCountDetailsHeader/CycleCountDetailsHeaderSkeleton';

export const CycleCountDetails = () => {
  const [progressValue, setProgressValue] = useState(0);
  const { id, countTypeCd } = useParams();
  const resultCountTypeCd = checkCountTypeCd(countTypeCd || '');

  const { wallToWallData: getHeaderComponentData, isLoading } = useCycleCount({
    searchPage: {
      page: PAGE,
      size: SINGLE_PAGE_SIZE,
      sort: [
        {
          sortBy: SORT_BY.END_TS,
          direction: SORT_ORDER.DESC,
        },
      ],
    },
    searchCriteria: {
      countRequest: {
        countTypeCd: resultCountTypeCd,
        taskId: id,
      },
      entitiyAssociations: [
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_LAYOUT_SUBZONE,
        CYCLE_COUNT_ENTITY_ASSOCIATION.COUNT_ASSIGNED_USER,
      ],
    },
  });

  const { year, phase, week, statusCd, startTs, endTs } = getHeaderComponentData?.content[0] || {};
  const phaseData = { startTs, endTs, phase };

  const headerProps = {
    progressValue,
    taskId: id,
    year: year ?? 0,
    countTypeCd: countTypeCd ?? '',
    phase: phase ?? 0,
    week: week ?? 0,
    statusCd: statusCd ?? '',
    startTs: startTs ?? '',
  };

  return (
    <DownloadProvider>
      <View direction="column" height="100%">
        {isLoading ? (
          <CycleCountDetailsHeaderSkeleton />
        ) : (
          <CycleCountDetailsHeader {...headerProps} />
        )}

        <CycleCountDetailsTabs countTypeCdValue={countTypeCd ?? ''} taskId={id ?? ''} />
        <Outlet context={[progressValue, setProgressValue, phaseData]} />
      </View>
    </DownloadProvider>
  );
};
