/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { z } from 'zod';

export const LayoutAttributeSchema = z.object({
  name: z.string().nullable(),
  value: z.string().nullable(),
});

export const LayoutSchema = z.object({
  layoutKey: z.string().nullable(),
  layoutName: z.string().nullable(),
  layoutNameDesc: z.string().nullable(),
  layoutDistinctName: z.string().nullable(),
  entityCd: z.string().nullable(),
  entityTypeCd: z.string().nullable(),
  active: z.boolean(),
  mapSequence: z.number().nullable(),
  attributes: z.array(LayoutAttributeSchema).optional().nullable(),
});

export const LayoutParentSchema = z.object({
  layoutName: z.string().nullable(),
  layoutNameDesc: z.string().nullable(),
  layoutDistinctName: z.string().nullable(),
  mapSequence: z.number().nullable(),
});

export const LayoutChildrenSchema = z.object({
  layoutName: z.string().nullable(),
  layoutNameDesc: z.string().nullable(),
  layoutDistinctName: z.string().nullable(),
});

export const LayoutXREFSSchema = z.object({
  fromDistinctName: z.string().nullable(),
  toDistinctName: z.string().nullable(),
  priority: z.number().nullable(),
});

export const LayoutProductsSchema = z.object({
  productId: z.string().nullable(),
  min: z.number().nullable(),
  max: z.number().nullable(),
});

export const LayoutAssetsSchema = z.object({
  assetLayoutMapKey: z.string().nullable(),
  assetTypeCd: z.string().nullable(),
  assetCd: z.string().nullable(),
  assetDesc: z.string().nullable(),
  assetAttributes: z.array(LayoutAttributeSchema).nullable(),
  assetLayoutMappingAttrib: z.array(LayoutAttributeSchema),
});

export const LayoutSearchSchema = z.object({
  layout: LayoutSchema,
  parent: LayoutParentSchema.nullable(),
  children: z.array(LayoutChildrenSchema),
  xrefs: z.array(LayoutXREFSSchema),
  products: z.array(LayoutProductsSchema),
  assets: z.array(LayoutAssetsSchema),
});
