/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState, useEffect, memo } from 'react';
import { View, Skeleton } from '@az/starc-ui';
import { useAtom } from 'jotai';

import { useCycleCount } from '@inventory/services/hooks/useCycleCounts';
import { CYCLE_COUNT_STATUS_CD, PAGE, DateFilters } from '@inventory/constants/constants';
import { usePreparedFilterOptions } from '@inventory/hooks/usePreparedFilterOptions';
import { locationCountSearchType } from '@inventory/types/types';
import { fiscalCalendarInfoAtom } from '@inventory/atoms/fiscalCalendarInfo/fiscalCalendarInfo';

import { CombinedPillTabs } from '@shared/components/CombinedPillTabs/CombinedPillTabs';
import { PAGE_SIZE, SORT_BY, SORT_ORDER } from '@shared/constants/constants';

import { FilterOption, CycleCountFilterProps } from './CycleCountFilter.types';

const getDistinctTasksByPhase = (tasks: locationCountSearchType[]): locationCountSearchType[] => {
  const seenPhases = new Set();
  return tasks.filter((task: locationCountSearchType) => {
    if (task.phase && !seenPhases.has(task.phase)) {
      seenPhases.add(task.phase);
      return true;
    }
    return false;
  });
};

export const CycleCountFilter = memo(
  ({ countTypeCd, selectedFilter, setSelectedFilter }: CycleCountFilterProps) => {
    const [fiscalCalendarInfo] = useAtom(fiscalCalendarInfoAtom);

    const { defaultOptions } = usePreparedFilterOptions({
      countTypeCd: countTypeCd,
    });

    const fiscalYear = fiscalCalendarInfo?.year?.index ?? new Date().getFullYear();

    const [dynamicPills, setDynamicPills] = useState<FilterOption[]>(defaultOptions);

    const { wallToWallData, isLoading } = useCycleCount({
      searchPage: {
        page: PAGE,
        size: PAGE_SIZE,
        sort: [
          {
            sortBy: SORT_BY.END_TS,
            direction: SORT_ORDER.DESC,
          },
        ],
      },
      searchCriteria: {
        countRequest: {
          countTypeCd: countTypeCd,
          statusCd: [
            CYCLE_COUNT_STATUS_CD.NEW,
            CYCLE_COUNT_STATUS_CD.INPROGRESS,
            CYCLE_COUNT_STATUS_CD.COMPLETE,
          ],
          year: fiscalYear,
        },
      },
    });

    useEffect(() => {
      if (!wallToWallData?.content) return;

      const distinctTasks: locationCountSearchType[] = getDistinctTasksByPhase(
        wallToWallData.content
      );
      const phasePills = distinctTasks.map((task: locationCountSearchType) => ({
        value: `${task.phase}`,
        label: `P${task.phase}`,
        fromTs: task.startTs,
        toTs: task.endTs,
      }));

      const last4WeeksIndex = defaultOptions.findIndex(
        (filter) => filter.value === DateFilters.LAST_4_WEEKS
      );

      const newDynamicPills = [
        ...defaultOptions.slice(0, last4WeeksIndex + 1),
        ...phasePills,
        ...defaultOptions.slice(last4WeeksIndex + 1),
      ];

      setDynamicPills(newDynamicPills);
    }, [defaultOptions, wallToWallData]);

    if (isLoading) {
      return (
        <View direction="row" gap={2} align="center">
          {Array(5)
            .fill(null)
            .map((_, index) => (
              <View.Item key={index}>
                <Skeleton borderRadius="round" height="40px" width="100px" />
              </View.Item>
            ))}
        </View>
      );
    }

    return (
      <CombinedPillTabs<FilterOption>
        TabsFilterData={dynamicPills}
        onTabSelect={setSelectedFilter}
        selectedValue={selectedFilter}
      />
    );
  }
);
