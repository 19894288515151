/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { t } from 'i18next';

import { View, Text } from '@az/starc-ui';

import { NOTIFICATION_TYPES } from '@shared/constants/constants';
import { PAGE_URLS } from '@shared/constants/routes';

import { useAtom } from 'jotai';

import { useNotificationHandler } from '@shared/hooks/useNotificationHandler';
import { Modal } from '@outbound/components/Modal/Modal';
import { ConfirmationModal } from '@outbound/components/Modal/ConfirmationModal';

import { ReleaseToPaperAccordion } from './ReleaseToPaperAccordion';
import { SubzoneAccordion } from './SubzoneAccordion';

import { selectedOrdersLaneAtom } from '@outbound/atoms/releaseOrder/releaseOrderAtom';
import {
  releaseToPaperSubzonesAtom,
  releaseToPaperLaneConfirmationAtom,
  printAndReleaseAtom,
} from '@outbound/atoms/releaseToPaper/releaseToPaperAtom';

import { releaseOrders } from '../OrderTabs/data';
import { Props } from './ReleaseToPaperModal.types';
import { releaseToPaperOrders } from './data';

export const ReleaseToPaperModal = ({ isOpen, onClose, storeNumber, subzone }: Props) => {
  /*Atoms*/
  const [, setSelectedStoresLane] = useAtom(selectedOrdersLaneAtom);
  const [selectedSubzones, setSelectedSubzones] = useAtom(releaseToPaperSubzonesAtom);
  const [releaseToPaperLaneConfirmation, setReleaseToPaperLaneConfirmation] = useAtom(
    releaseToPaperLaneConfirmationAtom
  );
  const [printAndRelease, setPrintAndRelease] = useAtom(printAndReleaseAtom);

  /* Constants */
  const { handleNotification } = useNotificationHandler();
  const navigate = useNavigate();
  const location = useLocation();

  /* State variables */
  const [showReleaseToPaperOpen, setReleaseToPaperOpen] = useState(false);

  /* Functions */
  const onNavigateToControlDesk = () => {
    const selectedTabRoute = location.pathname.split('/').at(-1);
    navigate(`${PAGE_URLS.OUTBOUND_CONTROL_DESK}/${selectedTabRoute}`);
  };

  const onNext = () => {
    releaseToPaperLaneConfirmation
      ? setPrintAndRelease(true)
      : setReleaseToPaperLaneConfirmation(true);
    if (printAndRelease) {
      handleNotification(
        NOTIFICATION_TYPES.SUCCESS,
        t('OutboundMatrix.ReleaseToPaper.ReleaseToPaperText', {
          storeNumber: storeNumber,
          route: releaseToPaperOrders[0].routeNo,
        })
      );
      onNavigateToControlDesk();
      setReleaseToPaperLaneConfirmation(false);
      setPrintAndRelease(false);
      setSelectedSubzones([]);
      setSelectedStoresLane([]);
    }
  };

  const onCloseReleasetoPaperModal = () => {
    onClose(false);
    setReleaseToPaperOpen(false);
    setReleaseToPaperLaneConfirmation(false);
    setSelectedSubzones([]);
    setPrintAndRelease(false);
    setSelectedStoresLane([]);
  };
  return (
    <>
      <ConfirmationModal
        title={t('ConfirmationComment.ReleaseToPaperTitle')}
        isCancelBtn={true}
        open={isOpen}
        onClose={() => onClose(false)}
        onSuccess={() => setReleaseToPaperOpen(true)}
        primaryBtnText={t('ConfirmationComment.YesProceed')}
      >
        <Text>{t('ConfirmationComment.ReleaseToPaperSubtitle')}</Text>
      </ConfirmationModal>
      <Modal
        title={t('OutboundMatrix.ReleaseToPaper.Title')}
        subTitle={
          releaseToPaperLaneConfirmation
            ? printAndRelease
              ? t('OutboundMatrix.ReleaseToPaper.SubTitleStep_three')
              : t('OutboundMatrix.ReleaseToPaper.SubTitleStep_two')
            : t('OutboundMatrix.ReleaseToPaper.SubTitleStep_one')
        }
        open={showReleaseToPaperOpen}
        isCancelBtn={!releaseToPaperLaneConfirmation}
        isPrimaryBtnDisabled={!selectedSubzones?.length && !subzone}
        primaryBtnText={
          !printAndRelease
            ? t('OutboundMatrix.ReleaseToPaper.NextButton')
            : t('OutboundMatrix.ReleaseToPaper.PrintAndRelease')
        }
        onClose={onCloseReleasetoPaperModal}
        onSuccess={onNext}
        size="medium"
        {...(releaseToPaperLaneConfirmation && !printAndRelease && !subzone
          ? { previousButtonHandler: () => setReleaseToPaperLaneConfirmation(false) }
          : {})}
      >
        {!printAndRelease ? (
          <View gap={6}>
            {releaseToPaperOrders.map((store) => (
              <ReleaseToPaperAccordion
                storeNumber={storeNumber}
                subzone={subzone}
                item={store}
                order={releaseOrders.releaseToPaper}
              />
            ))}
          </View>
        ) : (
          <View gap={6}>
            {releaseToPaperOrders.map((store) => (
              <SubzoneAccordion
                storeNumber={storeNumber}
                item={store}
                order={releaseOrders.releaseToPaper}
                subzone={subzone}
              />
            ))}
          </View>
        )}
      </Modal>
    </>
  );
};
