/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const DIF_RELEASE_ORDER_BY_AREA_COLUMNS = [
  {
    label: '',
    id: 'area-select',
    textAlign: 'start' as const,
    width: 'var(--st-unit-1)',
    isCheckbox: true,
  },
  {
    label: 'Table.OrderRelease.Area',
    id: 'area',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.SKU',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'calc(var(--st-unit-28) * 2)' },
];

const LANE_CONFIRMATION_COLUMNS = [
  {
    label: 'Table.OrderRelease.DC',
    id: 'area',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-30) * 2)',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Area',
    id: 'sku',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pallets',
    id: 'pallet',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  {
    label: 'Table.OrderRelease.Pieces',
    id: 'pieces',
    textAlign: 'start' as const,
    width: 'calc(var(--st-unit-20))',
    isSorted: false,
  },
  { label: '', id: 'empty', textAlign: 'center' as const, width: 'calc(var(--st-unit-22) * 2)' },
];

export { DIF_RELEASE_ORDER_BY_AREA_COLUMNS, LANE_CONFIRMATION_COLUMNS };
