/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState } from 'react';

import { View } from '@az/starc-ui';

import { Props } from './LaneConfirmationAccordion.types.tsx';

import {
  LAYOUT_STATUS_CD,
  LAYOUT_TYPE_CD,
  RELEASE_GROUPS_GROUP_TYPE,
  RELEASE_GROUPS_ORDER_TYPE,
  RELEASE_ORDER_STATUS,
} from '@outbound/constants/constants.ts';
import { useGetReleaseGroups } from '@outbound/services/hooks/useGetReleaseGroups.tsx';
import { DEFAULT_PAGE, MAX_PAGE_SIZE, PAGE_SIZE } from '@shared/constants/constants.ts';
import { TableStylingVariants } from '@shared/components/Table/tableConstants.ts';
import { Table } from '@shared/components/Table/Table.tsx';
import { mapDifLaneConfirmationTableRows } from '@outbound/utils/table/releaseOrderTableUtils.tsx';
import { LANE_CONFIRMATION_COLUMNS } from '@shared/components/Table/tableColumnData.ts';
import { useGetReleaseGroupLanes } from '@outbound/services/hooks/useGetReleaseGroupLanes.tsx';

import style from './LaneConfirmationList.module.scss';
import { useAtom } from 'jotai/index';
import { selectedReleaseOrderLaneAtom } from '@outbound/atoms/releaseOrder/releaseOrderAtom.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';

export const LaneConfirmationList = ({ releaseGroup, orderType }: Props) => {
  /* Atom */
  const [selectedReleaseOrderLane, setSelectedReleaseOrderLane] = useAtom(
    selectedReleaseOrderLaneAtom
  );

  /* State */
  const [laneOptions, setLaneOptions] = useState<SelectOption[]>([]);

  /* variables */
  const { groupId } = releaseGroup;

  /* Constants */
  const { releaseGroupsData, isFetching: isReleaseGroupLoading } = useGetReleaseGroups({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    orderType: orderType,
    parentReleaseGroupId: groupId,
    groupTypes: [
      orderType === RELEASE_GROUPS_ORDER_TYPE.DIF_WORK_ORDER
        ? RELEASE_GROUPS_GROUP_TYPE.BY_DC
        : RELEASE_GROUPS_GROUP_TYPE.BY_ORDER,
    ],
  });

  const { releaseGroupLanesData } = useGetReleaseGroupLanes({
    currentPage: DEFAULT_PAGE - 1,
    pageSize: MAX_PAGE_SIZE,
    layoutTypeCds: [LAYOUT_TYPE_CD.DOCK],
    layoutStatusCds: [LAYOUT_STATUS_CD.OPEN],
  });

  /* Functions */
  const handleLaneChange = (releaseGroupId: string, laneId: string) => {
    const existingLaneData = [...selectedReleaseOrderLane];
    const existingReleaseOrderLane = existingLaneData.find(
      (releaseOrderLane) => releaseOrderLane.groupId === releaseGroupId
    );

    if (existingReleaseOrderLane) {
      existingReleaseOrderLane.laneId = laneId;
    } else {
      existingLaneData.push({
        groupId: releaseGroupId,
        laneId: laneId,
      });
    }

    setSelectedReleaseOrderLane([...existingLaneData]);
  };

  useEffect(() => {
    const laneOptionData = [
      {
        label: '',
        value: '',
      },
    ];

    for (const releaseGroupLane of releaseGroupLanesData?.content || []) {
      laneOptionData.push({
        label: releaseGroupLane.layoutName,
        value: releaseGroupLane.layoutDistinctName,
      });
    }

    setLaneOptions([...laneOptionData]);
  }, [releaseGroupLanesData]);

  return (
    <>
      <View className={style['lane-confirmation-list']}>
        <Table
          columns={LANE_CONFIRMATION_COLUMNS}
          rows={mapDifLaneConfirmationTableRows(
            releaseGroupsData?.content.filter(
              (releaseGroup) => releaseGroup.status !== RELEASE_ORDER_STATUS.RELEASED
            ) || [],
            laneOptions,
            selectedReleaseOrderLane,
            handleLaneChange
          )}
          isPaginated={true}
          isCheckboxDisabled={true}
          pageSize={PAGE_SIZE}
          defaultPage={DEFAULT_PAGE}
          isCreditItem={false}
          isCheckboxTable={false}
          showDeselectCheckbox={false}
          isApiLoadedData={true}
          isLoading={isReleaseGroupLoading}
          styleVariant={TableStylingVariants.DETAILS}
          totalPages={releaseGroupsData?.totalPages || 0}
          onSort={() => ({})}
        />
      </View>
    </>
  );
};
