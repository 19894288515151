/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  classNames,
  Text,
  Button,
  Checkbox,
  Divider,
  Icon,
  Link,
  Modal,
  SearchBar,
  View,
  useDebounce,
} from '@az/starc-ui';
import { Props } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/AddSubzoneVehicleModal.types.ts';
import style from '@mdm/pages/RDM/LocationManager/Subzone/Detail/AddSubzoneVehicleModal.module.scss';
import { ActionDelete, Close } from '@az/starc-ui-icons';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';
import {
  ASSET_DEFAULT_SORT_FIELD,
  ASSET_TYPE_CODE,
  ENTITY_ASSOCIATIONS,
  VEHICLE_FIELD,
} from '@mdm/constants/constants.ts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AttributeType, LayoutAssetType } from '@mdm/types/schema.type.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions.tsx';
import { VehiclesSuggestionSkeleton } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/VehiclesSuggestion/VehiclesSuggestionSkeleton.tsx';
import { VehiclesSuggestion } from '@mdm/pages/RDM/LocationManager/Subzone/Detail/VehiclesSuggestion/VehiclesSuggestion.tsx';
import {
  ASSET,
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  RDM,
} from '@shared/constants/constants.ts';
import { useGetVehicles } from '@mdm/services/hooks/useGetVehicles.tsx';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { useMutateSubzone } from '@mdm/services/hooks/useMutateSubzone.ts';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';

export const AddVehicleSubzoneModal = ({
  isOpen,
  onClose,
  subzoneData,
  parentLayoutDistinctName,
  onAttributeChange,
}: Props) => {
  /* State */
  const [searchValue, setSearchValue] = useState<string>('');
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<SelectOption[]>([]);
  const [existingVehicles, setExistingVehicles] = useState<LayoutAssetType[]>([]);
  const [vehicleAssetLayoutMappingData, setVehicleAssetLayoutMappingData] = useState<
    LayoutAssetType[]
  >([]);

  /* Constants */
  const { isLoading: isSubzoneUpdating, mutateSubzone } = useMutateSubzone();
  const { mutateSubzone: mutateDeleteVehicle } = useMutateSubzone();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();
  const debouncedSearchQueryValue = useDebounce(searchValue, DEBOUNCE_TIMER);

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: ASSET,
  });

  const { t } = useTranslation();

  const { vehiclesData, isFetching: isLoading } = useGetVehicles(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: PAGE_SIZE,
      assetCd: debouncedSearchQueryValue || '',
      sortBy: ASSET_DEFAULT_SORT_FIELD,
      direction: 'ASC',
      entityAssociations: [ENTITY_ASSOCIATIONS.ATTRIBUTES, ENTITY_ASSOCIATIONS.LAYOUTS],
    },
    !!debouncedSearchQueryValue
  );

  /* Function */
  const onItemClick = (asset: LayoutAssetType) => {
    const layoutAssetMappingData = [...existingVehicles];
    const foundLayoutIndex = existingVehicles.findIndex(
      (layout) => layout.assetCd === asset.assetCd
    );

    if (foundLayoutIndex !== -1) {
      layoutAssetMappingData.splice(foundLayoutIndex, 1);
    } else {
      layoutAssetMappingData.push(asset);
    }

    setExistingVehicles(layoutAssetMappingData);
  };

  const handleAttributeClick = (
    data: LayoutAssetType,
    value: string | boolean,
    attributeName: string
  ) => {
    const layoutAssetMappingData = [...existingVehicles];
    const foundAssetIndex = layoutAssetMappingData.findIndex(
      (asset) => asset.assetCd === data.assetCd
    );

    if (foundAssetIndex !== -1) {
      onAttributeChange(value.toString(), attributeName, data.assetCd);
    }

    if (foundAssetIndex !== -1) {
      const foundAttributeIndex = (
        layoutAssetMappingData[foundAssetIndex].assetLayoutMappingAttrib || []
      ).findIndex((assetLayout) => assetLayout.name === attributeName);
      if (foundAttributeIndex !== -1) {
        (layoutAssetMappingData[foundAssetIndex].assetLayoutMappingAttrib || [])[
          foundAttributeIndex
        ].value = value.toString();
      }

      setExistingVehicles([...layoutAssetMappingData]);
    }
  };

  const handleOnClose = () => {
    if (
      (subzoneData?.assets || []).filter((asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE)
        .length !== existingVehicles.length
    ) {
      if (window.confirm(t('UnsavedChangesWarning'))) {
        setSearchValue('');
        onClose();
      }
    } else {
      setSearchValue('');
      onClose();
    }
  };

  const handleOnDelete = async (assetCd: string) => {
    if (subzoneData && subzoneData.layoutKey) {
      const layoutAssetMappingData = [...existingVehicles];

      const foundAssetIndex = layoutAssetMappingData.findIndex((asset) => {
        return asset.assetCd === assetCd;
      });

      if (foundAssetIndex !== -1) {
        layoutAssetMappingData[foundAssetIndex].isActive = false;
      }

      const foundContainerAsset = subzoneData.assets?.find(
        (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.CONTAINER
      );
      if (foundContainerAsset) {
        const foundContainerInLayoutAssetMappingData = layoutAssetMappingData.find(
          (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.CONTAINER
        );

        if (!foundContainerInLayoutAssetMappingData) {
          layoutAssetMappingData.push(foundContainerAsset);
        }
      }

      setExistingVehicles(
        layoutAssetMappingData.filter((asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE)
      );

      let attributes: AttributeType[] = [];
      if (subzoneData.attributes) {
        attributes = subzoneData.attributes
          .filter((attribute) => attribute.value !== '')
          .map((attribute) => {
            return {
              ...attribute,
              value: attribute.value.toString(),
            };
          });
      }

      const payload = {
        ...subzoneData,
        parentLayoutDistinctName,
        attributes,
        assets:
          layoutAssetMappingData.length > 0
            ? layoutAssetMappingData.filter((asset) => !!asset.assetCd)
            : null,
      };

      mutateDeleteVehicle(
        {
          layoutKey: subzoneData.layoutKey,
          layoutRequest: payload,
        },
        {
          onSuccess: () => {
            const successMessage = t('Success.Action.Vehicle.Deleted');

            queryClient.invalidateQueries(['subzones']);
            queryClient.invalidateQueries(['subzone']);
            handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
          },
        }
      );
    }
  };

  const handleOnSubmit = (context = 'subzone', updateFieldName = '') => {
    if (subzoneData && subzoneData.layoutKey) {
      const assets = existingVehicles.length > 0 ? existingVehicles : [];
      const foundContainerAsset = subzoneData.assets?.find(
        (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.CONTAINER
      );

      if (foundContainerAsset) {
        const foundContainerInLayoutAssetMappingData = assets.find(
          (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.CONTAINER
        );

        if (!foundContainerInLayoutAssetMappingData) {
          assets.push(foundContainerAsset);
        }
      }

      let attributes: AttributeType[] = [];
      if (subzoneData.attributes) {
        attributes = subzoneData.attributes
          .filter((attribute) => attribute.value !== '')
          .map((attribute) => {
            return {
              ...attribute,
              value: attribute.value.toString(),
            };
          });
      }

      const payload = {
        ...subzoneData,
        attributes,
        assets: assets.filter((asset) => !!asset.assetCd),
      };

      mutateSubzone(
        {
          layoutKey: subzoneData.layoutKey,
          layoutRequest: payload,
        },
        {
          onSuccess: (data) => {
            if (data) {
              let successMessage = t('Success.Action.Vehicle.Added');

              if (context === 'vehicle') {
                successMessage = t('Success.Action.Vehicle.Updated', {
                  vehicleName: updateFieldName,
                });
              }

              queryClient.invalidateQueries(['subzones']);
              queryClient.invalidateQueries(['subzone']);
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);

              if (context === 'subzone') {
                onClose();
              }
            }
          },
        }
      );
    }
  };

  const handleSearchSuggestions = () => {
    if (isLoading) {
      return <VehiclesSuggestionSkeleton />;
    } else if (searchValue && vehicleAssetLayoutMappingData.length === 0) {
      return <EmptySuggestions />;
    } else if (searchValue && vehicleAssetLayoutMappingData.length > 0) {
      return (
        <VehiclesSuggestion
          key={searchValue}
          data={vehicleAssetLayoutMappingData}
          vehiclesData={vehiclesData?.content || []}
          onItemClick={onItemClick}
          onAttributeClick={handleAttributeClick}
          existingVehicles={existingVehicles}
          configData={{
            vehicleTypeOptions,
          }}
        />
      );
    } else {
      return <EmptySuggestions />;
    }
  };

  /* Hooks */
  useEffect(() => {
    const vehicleTypes = (configsData || [])
      .filter((config) => config.configCd === VEHICLE_FIELD.VEHICLE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setVehicleTypeOptions(vehicleTypes);
  }, [configsData]);

  useEffect(() => {
    let layoutAssetMappingData: LayoutAssetType[] = [];
    if (vehiclesData?.content) {
      layoutAssetMappingData = vehiclesData.content.map((asset) => ({
        assetCd: asset.assetCd,
        assetTypeCd: asset.assetTypeCd,
        assetDesc: asset.assetDesc,
        assetAttributes: asset.attributes,
        assetLayoutMappingAttrib: [
          {
            name: VEHICLE_FIELD.PICKING_ALLOWED,
            value: 'false',
          },
          {
            name: VEHICLE_FIELD.PUTAWAY_ALLOWED,
            value: 'false',
          },
        ],
      }));
    }

    setVehicleAssetLayoutMappingData(layoutAssetMappingData);
  }, [vehiclesData]);

  useEffect(() => {
    if (subzoneData && subzoneData.assets) {
      const vehicles = subzoneData.assets.filter(
        (asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE
      );
      setExistingVehicles(structuredClone(vehicles));
    }
  }, [isOpen, subzoneData]);

  return (
    <Modal
      open={isOpen}
      size="medium"
      onClose={() => handleOnClose()}
      className={style['add-vehicle-subzone-modal']}
    >
      <View className={style['add-vehicle-subzone-modal__header']}>
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {t('SubzoneDetails.AddVehicleModal.Title')}
          </Text>
          <Text color="primary">{t('SubzoneDetails.AddVehicleModal.Subtitle')}</Text>
        </View>
        <Button
          variant="ghost"
          onClick={handleOnClose}
          className={classNames(
            style['close-icon'],
            style['add-vehicle-subzone-modal__header__close-button']
          )}
        >
          <Icon svg={Close} />
        </Button>
      </View>
      <View direction="column" className={style['add-vehicle-subzone-modal__body']}>
        <View.Item>
          <SearchBar
            value={searchValue}
            onValueChange={setSearchValue}
            suggestions={searchValue ? handleSearchSuggestions() : []}
            className={style['add-vehicle-subzone-modal__body__search-bar']}
            label={t('SubzoneDetails.AddVehicleModal.SearchPlaceholder')}
            maxMenuHeight={300}
          />
        </View.Item>
        <View.Item>
          <View padding={[6, 0]} />
        </View.Item>
        <View.Item>
          <View direction="column" className={style['add-vehicle-subzone-modal__body__vehicles']}>
            <View.Item>
              {existingVehicles
                .filter((asset) => asset.assetTypeCd === ASSET_TYPE_CODE.VEHICLE)
                .map((asset, index) => {
                  return (
                    <>
                      <View
                        key={asset.assetCd}
                        className={style['add-vehicle-subzone-modal__body__vehicles_item']}
                        direction="row"
                        align="center"
                      >
                        <View.Item grow>
                          <View direction="row">
                            <View.Item>
                              <Text weight="bold">{asset.assetCd}</Text>
                            </View.Item>
                            <Divider
                              vertical
                              color="600"
                              attributes={{
                                style: {
                                  height: 'var(--st-unit-5)',
                                  margin: '0 var(--st-unit-2)',
                                },
                              }}
                            />
                            <View.Item>
                              <Text>
                                {vehicleTypeOptions.find(
                                  (option) =>
                                    option.value ===
                                    (asset.assetAttributes
                                      ? asset.assetAttributes[
                                          `${getFormFieldIndexByKey(
                                            asset.assetAttributes,
                                            VEHICLE_FIELD.VEHICLE_TYPE
                                          )}`
                                        ]?.value
                                      : '')
                                )?.label || '-'}
                              </Text>
                            </View.Item>
                          </View>
                          {asset.assetDesc && (
                            <View.Item
                              attributes={{
                                style: {
                                  marginTop: 'var(--st-unit-2)',
                                },
                              }}
                            >
                              <Text color="500">{asset.assetDesc}</Text>
                            </View.Item>
                          )}
                        </View.Item>

                        <View.Item>
                          <View direction="row" align="center">
                            <View.Item>
                              <Checkbox
                                name={`assetLayoutMappingAttrib.${getFormFieldIndexByKey(
                                  asset.assetLayoutMappingAttrib || [],
                                  VEHICLE_FIELD.PICKING_ALLOWED
                                )}.value`}
                                className={style['checkbox-field']}
                                label={t('Table.VehicleList.PickingAllowed')}
                                value="false"
                                defaultChecked={
                                  !!(
                                    asset.assetLayoutMappingAttrib &&
                                    asset.assetLayoutMappingAttrib[
                                      `${getFormFieldIndexByKey(
                                        asset.assetLayoutMappingAttrib,
                                        VEHICLE_FIELD.PICKING_ALLOWED
                                      )}`
                                    ]?.value === 'true'
                                  )
                                }
                                onChange={(e) =>
                                  handleAttributeClick(
                                    asset,
                                    e.target.checked,
                                    VEHICLE_FIELD.PICKING_ALLOWED
                                  )
                                }
                              />
                            </View.Item>
                            <View.Item>
                              <Checkbox
                                name={`assetLayoutMappingAttrib.${getFormFieldIndexByKey(
                                  asset.assetLayoutMappingAttrib || [],
                                  VEHICLE_FIELD.PUTAWAY_ALLOWED
                                )}.value`}
                                className={style['checkbox-field']}
                                label={t('Table.VehicleList.PutawayAllowed')}
                                value="false"
                                defaultChecked={
                                  !!(
                                    asset.assetLayoutMappingAttrib &&
                                    asset.assetLayoutMappingAttrib[
                                      `${getFormFieldIndexByKey(
                                        asset.assetLayoutMappingAttrib,
                                        VEHICLE_FIELD.PUTAWAY_ALLOWED
                                      )}`
                                    ]?.value === 'true'
                                  )
                                }
                                onChange={(e) =>
                                  handleAttributeClick(
                                    asset,
                                    e.target.checked,
                                    VEHICLE_FIELD.PUTAWAY_ALLOWED
                                  )
                                }
                              />
                            </View.Item>
                          </View>
                        </View.Item>
                        <Divider
                          vertical
                          color="500"
                          attributes={{
                            style: {
                              height: 'var(--st-unit-5)',
                              margin: '0 var(--st-unit-2)',
                            },
                          }}
                        />
                        <View.Item>
                          <Link onClick={() => handleOnDelete(asset.assetCd)}>
                            <Icon svg={ActionDelete} color="error" />
                          </Link>
                        </View.Item>
                      </View>
                      {index !== existingVehicles.length - 1 && (
                        <Divider
                          className={
                            style['add-vehicle-subzone-modal__body__vehicles_item__separator']
                          }
                          color="300"
                        />
                      )}
                    </>
                  );
                })}
            </View.Item>
          </View>
        </View.Item>
      </View>
      <View className={style['add-vehicle-subzone-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={style['add-vehicle-subzone-modal__footer__actions']}
        >
          <Button
            variant="secondary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnClose}
          >
            <Text>{t('Cancel')}</Text>
          </Button>

          <Button
            variant="primary"
            loading={isSubzoneUpdating}
            attributes={{ style: { width: 'fit-content' } }}
            onClick={() => handleOnSubmit()}
          >
            <Text>{t('SubzoneDetails.AddVehicleModal.Title')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
