/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import {
  TOP_VARIANCE_PILLS,
  RBCC_FILTERS,
  CYCLE_COUNT_TYPE_CD,
} from '@inventory/constants/constants';
import { mapDateFilterToRange, getRangeFromFiscalInfo } from '@inventory/utils/topVarianceUtils';
import { fiscalCalendarInfoAtom } from '@inventory/atoms/fiscalCalendarInfo/fiscalCalendarInfo';

type Props = {
  countTypeCd: string;
};

export const usePreparedFilterOptions = ({ countTypeCd }: Props) => {
  const { t } = useTranslation();
  const [fiscalCalendarInfo] = useAtom(fiscalCalendarInfoAtom);

  const currentFilters = useMemo(() => {
    const filterConfig = {
      [CYCLE_COUNT_TYPE_CD.RBCC]: RBCC_FILTERS,
      [CYCLE_COUNT_TYPE_CD.WALL_TO_WALL]: TOP_VARIANCE_PILLS,
    };
    return filterConfig[countTypeCd] || [];
  }, [countTypeCd]);

  const defaultFilter = useMemo(() => {
    const range =
      getRangeFromFiscalInfo(currentFilters[0].value, fiscalCalendarInfo) ||
      mapDateFilterToRange(currentFilters[0].value);

    return {
      ...currentFilters[0],
      ...range,
    };
  }, [currentFilters, fiscalCalendarInfo]);

  const defaultOptions = useMemo(
    () =>
      currentFilters.map((filter) => {
        const range =
          getRangeFromFiscalInfo(filter.value, fiscalCalendarInfo) ||
          mapDateFilterToRange(filter.value);

        return {
          value: filter.value,
          label: t(filter.label),
          ...range,
        };
      }),
    [currentFilters, fiscalCalendarInfo, t]
  );

  return {
    defaultFilter,
    defaultOptions,
  };
};
