/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getValidNumber, getValidPercentage } from '@shared/utils/commonUtils';
import type { DashboardMetrics } from '@inventory/types/types';

export const calculateAccuracyMeasurements = (data: DashboardMetrics) => {
  const {
    totalInvAmtCounted,
    totalInvAmtExpected,
    totalInvQtyCounted,
    totalInvQtyExpected,
    totalInvAmtAdjCalc,
    totalInvQtyAdjustedCalc,
  } = data.accuracyMeasurements || {};

  let netDollarAccuracy = 0;
  let absDollarAccuracy = 0;
  let totalDollarCounted = 0;
  let netDollarVariance = 0;
  let absDollarVariance = 0;
  let netPiecesAccuracy = 0;
  let absPiecesAccuracy = 0;
  let totalPiecesCounted = 0;
  let netPiecesVariance = 0;
  let absPiecesVariance = 0;

  if (totalInvAmtExpected !== null && totalInvAmtCounted !== null && totalInvAmtAdjCalc !== null) {
    netDollarVariance = totalInvAmtExpected - totalInvAmtCounted;
    absDollarVariance = (totalInvAmtCounted * 100) / totalInvAmtExpected;

    netDollarAccuracy = absDollarVariance;
    absDollarAccuracy = (totalInvAmtCounted - totalInvAmtAdjCalc) / totalInvAmtCounted;
    totalDollarCounted = totalInvAmtCounted;
  }

  if (
    totalInvQtyExpected !== null &&
    totalInvQtyCounted !== null &&
    totalInvQtyAdjustedCalc !== null
  ) {
    netPiecesVariance = totalInvQtyExpected - totalInvQtyCounted;
    absPiecesVariance = (totalInvQtyCounted * 100) / totalInvQtyExpected;

    netPiecesAccuracy = absPiecesVariance;
    absPiecesAccuracy = (totalInvQtyCounted - totalInvQtyAdjustedCalc) / totalInvQtyCounted;
    totalPiecesCounted = totalInvQtyCounted;
  }

  const accuracyMeasurementData = {
    netDollarAccuracy: getValidPercentage(netDollarAccuracy),
    absDollarAccuracy: getValidPercentage(absDollarAccuracy),
    totalDollarCounted: getValidNumber(totalDollarCounted.toFixed(2)),
    netDollarVariance: getValidNumber(netDollarVariance.toFixed(2)),
    absDollarVariance: getValidNumber(absDollarVariance.toFixed(2)),
    netPiecesAccuracy: getValidPercentage(netPiecesAccuracy),
    absPiecesAccuracy: getValidPercentage(absPiecesAccuracy),
    totalPiecesCounted: getValidNumber(totalPiecesCounted.toFixed(2)),
    netPiecesVariance: getValidNumber(netPiecesVariance.toFixed(2)),
    absPiecesVariance: getValidNumber(absPiecesVariance.toFixed(2)),
  };

  return accuracyMeasurementData;
};
