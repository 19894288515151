/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { Item } from '@outbound/components/OrderReleaseAccordion/OrderReleaseAccordion.types';
import { Store } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/NewStoreOrders/NewStore.types';
import { ltdGroupingRecemmendations } from '@outbound/pages/ControlDesk/ReleaseOrders/OrderTabs/data';
import { ReleaseOrderLane } from '@outbound/pages/ControlDesk/ReleaseOrders/LaneConfirmation/LaneConfirmationAccordion/LaneConfirmationAccordion.types.tsx';

export const selectedRootReleaseOrdersAtom = atomWithStorage<string[]>(
  'selectedRootReleaseOrders',
  []
);
export const selectedOrdersAtom = atomWithStorage<string[]>('selectedOrders', []);
export const selectedReleaseOrderLaneAtom = atom<ReleaseOrderLane[]>([]);
export const selectedSubzonesAtom = atom<string[]>([]);
export const selectedReleaseOrderTypeAtom = atomWithStorage<string>('selectedReleaseOrderType', '');
export const laneConfirmationItemAtom = atom<Item | Store | null>(null);
export const inLaneConfirmationAtom = atom<boolean>(false);
export const selectedOrdersLaneAtom = atom<{ store: string; lane: string | undefined }[]>([]);
export const ltdGroupedOrdersAtom = atom<string[][]>([]);
export const ltdGroupingRecommendationsAtom = atom<string[][]>(ltdGroupingRecemmendations);
export const replenishedAtom = atom<string[]>([]);
export const refreshReleaseOrdersAtom = atom<number>(0);
