/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  Button,
  Checkbox,
  classNames,
  Divider,
  Icon,
  Link,
  Modal,
  SearchBar,
  Text,
  useDebounce,
  View,
} from '@az/starc-ui';
import { ActionDelete, Close } from '@az/starc-ui-icons';
import { Props } from './AddVehicleSubzoneModal.types';
import { useEffect, useState } from 'react';

import style from './AddVehicleSubzoneModal.module.scss';
import { useTranslation } from 'react-i18next';
import { EmptySuggestions } from '@shared/components/EmptySuggestions/EmptySuggestions.tsx';
import { SubzonesSuggestion } from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/SubzonesSuggestion/SubzonesSuggestion.tsx';
import { useGetSubzones } from '@mdm/services/hooks/useGetSubzones.ts';
import {
  DEBOUNCE_TIMER,
  DEFAULT_PAGE,
  LAYOUT,
  NOTIFICATION_TYPES,
  PAGE_SIZE,
  RDM,
} from '@shared/constants/constants.ts';
import { useGetFacilityConfig } from '@shared/services/hooks/useGetFacilityConfig.ts';
import { SelectOption } from '@az/starc-ui/dist/components/select/Select.types';
import { ENTITY_ASSOCIATIONS, SUBZONE_FIELD, VEHICLE_FIELD } from '@mdm/constants/constants.ts';
import { AssetLayoutMappingType } from '@mdm/types/schema.type.ts';
import { useMutateVehicle } from '@mdm/services/hooks/useMutateVehicle.tsx';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationHandler } from '@shared/hooks/useNotificationHandler.ts';
import { SubzonesSuggestionSkeleton } from '@mdm/pages/RDM/LocationManager/Vehicle/Detail/SubzonesSuggestion/SubzonesSuggestionSkeleton.tsx';
import { getFormFieldIndexByKey } from '@mdm/utils/form/formUtils.tsx';

export const AddVehicleSubzoneModal = ({
  isOpen,
  onClose,
  vehicleData,
  onLayoutAttributeChange,
}: Props) => {
  /* State */
  const [searchValue, setSearchValue] = useState<string>('');
  const [subzoneTypeOptions, setSubzoneTypeOptions] = useState<SelectOption[]>([]);
  const [existingSubzones, setExistingSubzones] = useState<AssetLayoutMappingType[]>([]);
  const debouncedSearchQueryValue = useDebounce(searchValue, DEBOUNCE_TIMER);
  const [vehicleAssetLayoutMappingData, setVehicleAssetLayoutMappingData] = useState<
    AssetLayoutMappingType[]
  >([]);

  /* Constants */
  const { t } = useTranslation();
  const { isLoading: isVehicleUpdating, mutateVehicle } = useMutateVehicle();
  const { mutateVehicle: mutateDeleteSubzone } = useMutateVehicle();
  const queryClient = useQueryClient();
  const { handleNotification } = useNotificationHandler();

  const { configsData } = useGetFacilityConfig({
    domainCd: RDM,
    subDomainCd: LAYOUT,
  });

  const { subzonesData, isFetching: isLoading } = useGetSubzones(
    {
      currentPage: DEFAULT_PAGE - 1,
      pageSize: PAGE_SIZE,
      searchQuery: debouncedSearchQueryValue || '',
      sortBy: 'layoutName',
      direction: 'ASC',
      entityAssociations: [
        ENTITY_ASSOCIATIONS.LAYOUT_ATTRIBUTES,
        ENTITY_ASSOCIATIONS.LAYOUT_PARENT,
      ],
    },
    !!debouncedSearchQueryValue
  );

  /* Function */
  const onItemClick = (subzone: AssetLayoutMappingType) => {
    const assetLayoutMappingData = [...existingSubzones];
    const foundLayoutIndex = existingSubzones.findIndex(
      (layout) => layout.layoutDistinctName === subzone.layoutDistinctName
    );

    if (foundLayoutIndex !== -1) {
      assetLayoutMappingData.splice(foundLayoutIndex, 1);
    } else {
      assetLayoutMappingData.push(subzone);
    }

    setExistingSubzones(assetLayoutMappingData);
  };

  const handleAttributeClick = (
    data: AssetLayoutMappingType,
    value: string | boolean,
    attributeName: string
  ) => {
    const assetLayoutMappingData = existingSubzones;
    const foundLayoutIndex = assetLayoutMappingData.findIndex(
      (layout) => layout.layoutDistinctName === data.layoutDistinctName
    );

    if (foundLayoutIndex !== -1) {
      onLayoutAttributeChange(data.layoutDistinctName, attributeName, value.toString());
    }

    if (foundLayoutIndex !== -1) {
      const foundAttributeIndex = (
        assetLayoutMappingData[foundLayoutIndex].assetLayoutMappingAttributes || []
      ).findIndex((assetLayout) => assetLayout.name === attributeName);

      if (foundAttributeIndex > -1) {
        (assetLayoutMappingData[foundLayoutIndex].assetLayoutMappingAttributes || [])[
          foundAttributeIndex
        ].value = value.toString();
      }

      setExistingSubzones([...assetLayoutMappingData]);
    }
  };

  const handleOnClose = () => {
    if ((vehicleData?.layouts || []).length !== existingSubzones.length) {
      if (window.confirm(t('UnsavedChangesWarning'))) {
        setSearchValue('');
        onClose();
      }
    } else {
      setSearchValue('');
      onClose();
    }
  };

  const handleSearchSuggestions = () => {
    if (isLoading) {
      return <SubzonesSuggestionSkeleton />;
    } else if (searchValue && subzonesData?.content.length === 0) {
      return <EmptySuggestions />;
    } else if (searchValue && subzonesData && subzonesData?.content.length > 0) {
      return (
        <SubzonesSuggestion
          data={vehicleAssetLayoutMappingData}
          subzonesData={subzonesData?.content || []}
          onItemClick={onItemClick}
          onAttributeClick={handleAttributeClick}
          existingSubzones={existingSubzones}
          configData={{
            subzoneTypeOptions,
          }}
        />
      );
    } else {
      return <EmptySuggestions />;
    }
  };

  const handleOnDelete = async (layoutDistinctName: string) => {
    if (vehicleData && vehicleData.assetKey) {
      const assetLayoutMappingData = [...existingSubzones];

      const foundLayoutIndex = assetLayoutMappingData.findIndex((subzone) => {
        return subzone.layoutDistinctName === layoutDistinctName;
      });

      if (foundLayoutIndex !== -1) {
        assetLayoutMappingData[foundLayoutIndex].isActive = false;
      }

      setExistingSubzones(assetLayoutMappingData);

      const payload = {
        ...vehicleData,
        layouts: assetLayoutMappingData.length > 0 ? assetLayoutMappingData : [],
      };

      mutateDeleteSubzone(
        {
          assetKey: vehicleData.assetKey,
          assetRequest: payload,
        },
        {
          onSuccess: (data) => {
            if (data) {
              const successMessage = t('Success.Action.Subzone.Deleted');

              queryClient.invalidateQueries(['vehicles']);
              queryClient.invalidateQueries(['vehicle']);
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);
            }
          },
        }
      );
    }
  };

  const handleOnSubmit = (context = 'vehicle', updateFieldName = '') => {
    if (vehicleData && vehicleData.assetKey) {
      const payload = {
        ...vehicleData,
        layouts: existingSubzones.length > 0 ? existingSubzones : null,
      };

      mutateVehicle(
        {
          assetKey: vehicleData.assetKey,
          assetRequest: payload,
        },
        {
          onSuccess: (data) => {
            if (data) {
              let successMessage = t('Success.Action.Subzone.Added');

              if (context === 'subzone') {
                successMessage = t('Success.Action.Subzone.Updated', {
                  subzoneName: updateFieldName,
                });
              }

              queryClient.invalidateQueries(['vehicles']);
              queryClient.invalidateQueries(['vehicle']);
              handleNotification(NOTIFICATION_TYPES.SUCCESS, successMessage);

              if (context === 'vehicle') {
                onClose();
              }
            }
          },
        }
      );
    }
  };

  /* Hooks */
  useEffect(() => {
    const subzoneTypes = (configsData || [])
      .filter((config) => config.configCd === SUBZONE_FIELD.SUBZONE_TYPE)
      .map((config) => ({
        label: config.description,
        value: config.configValue,
      }));

    setSubzoneTypeOptions(subzoneTypes);
  }, [configsData]);

  useEffect(() => {
    if (vehicleData && vehicleData.layouts) {
      setExistingSubzones(structuredClone(vehicleData.layouts));
    }
  }, [isOpen, vehicleData]);

  useEffect(() => {
    let assetLayoutMappingData: AssetLayoutMappingType[] = [];
    if (subzonesData?.content) {
      assetLayoutMappingData = subzonesData.content.map((subzone) => ({
        layoutName: subzone.layout.layoutName,
        layoutDistinctName: subzone.layout.layoutDistinctName,
        layoutAttributes: subzone.layout.attributes,
        assetLayoutMappingAttributes: [
          {
            name: VEHICLE_FIELD.PICKING_ALLOWED,
            value: 'false',
          },
          {
            name: VEHICLE_FIELD.PUTAWAY_ALLOWED,
            value: 'false',
          },
        ],
      }));
    }

    setVehicleAssetLayoutMappingData(assetLayoutMappingData);
  }, [subzonesData]);

  return (
    <Modal
      open={isOpen}
      size="medium"
      onClose={() => handleOnClose()}
      className={style['add-subzone-vehicle-modal']}
    >
      <View className={style['add-subzone-vehicle-modal__header']}>
        <View direction="column">
          <Text as="h2" size="175" weight="bold" color="primary">
            {t('VehicleDetails.AddSubzoneModal.Title')}
          </Text>
          <Text color="primary">{t('VehicleDetails.AddSubzoneModal.Subtitle')}</Text>
        </View>
        <Button
          variant="ghost"
          onClick={handleOnClose}
          className={classNames(
            style['close-icon'],
            style['add-subzone-vehicle-modal__header__close-button']
          )}
        >
          <Icon svg={Close} />
        </Button>
      </View>
      <View direction="column" className={style['add-subzone-vehicle-modal__body']}>
        <View.Item>
          <SearchBar
            value={searchValue}
            onValueChange={setSearchValue}
            suggestions={searchValue ? handleSearchSuggestions() : []}
            className={style['add-subzone-vehicle-modal__body__search-bar']}
            label={t('VehicleDetails.AddSubzoneModal.SearchPlaceholder')}
            maxMenuHeight={300}
          />
        </View.Item>
        <View.Item>
          <View padding={[4, 0, 0, 0]} />
        </View.Item>
        <View.Item>
          <View direction="column" className={style['add-subzone-vehicle-modal__body__subzones']}>
            <View.Item>
              {existingSubzones.map((subzone, index) => {
                return (
                  <>
                    <View
                      key={subzone.assetLayoutMapKey}
                      className={style['add-subzone-vehicle-modal__body__subzones_item']}
                      direction="row"
                      align="center"
                    >
                      <View.Item grow>
                        <View direction="row">
                          <View.Item>
                            <Text weight="bold">
                              {subzone.layoutName || subzone.layoutDistinctName}
                            </Text>
                          </View.Item>
                          <Divider
                            vertical
                            color="600"
                            attributes={{
                              style: {
                                height: 'var(--st-unit-5)',
                                margin: '0 var(--st-unit-2)',
                              },
                            }}
                          />
                          <View.Item>
                            <Text>
                              {subzoneTypeOptions.find(
                                (option) =>
                                  option.value ===
                                  (subzone.layoutAttributes
                                    ? subzone.layoutAttributes[
                                        `${getFormFieldIndexByKey(
                                          subzone.layoutAttributes,
                                          SUBZONE_FIELD.SUBZONE_TYPE
                                        )}`
                                      ]?.value
                                    : '')
                              )?.label || '-'}
                            </Text>
                          </View.Item>
                        </View>
                        <View.Item
                          attributes={{
                            style: {
                              marginTop: 'var(--st-unit-2)',
                            },
                          }}
                        >
                          <Text color="500">{subzone.layoutDesc}</Text>
                        </View.Item>
                      </View.Item>

                      <View.Item>
                        <View direction="row" align="center">
                          <View.Item>
                            <Checkbox
                              className={style['checkbox-field']}
                              name={`assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                                subzone.assetLayoutMappingAttributes,
                                VEHICLE_FIELD.PICKING_ALLOWED
                              )}.value`}
                              label={t('Table.VehicleList.PickingAllowed')}
                              value="false"
                              defaultChecked={
                                !!(
                                  subzone.assetLayoutMappingAttributes &&
                                  subzone.assetLayoutMappingAttributes[
                                    `${getFormFieldIndexByKey(
                                      subzone.assetLayoutMappingAttributes,
                                      VEHICLE_FIELD.PICKING_ALLOWED
                                    )}`
                                  ]?.value === 'true'
                                )
                              }
                              onChange={(e) =>
                                handleAttributeClick(
                                  subzone,
                                  e.target.checked,
                                  VEHICLE_FIELD.PICKING_ALLOWED
                                )
                              }
                            />
                          </View.Item>
                          <View.Item>
                            <Checkbox
                              className={style['checkbox-field']}
                              name={`assetLayoutMappingAttributes.${getFormFieldIndexByKey(
                                subzone.assetLayoutMappingAttributes,
                                VEHICLE_FIELD.PUTAWAY_ALLOWED
                              )}.value`}
                              label={t('Table.VehicleList.PutawayAllowed')}
                              value="false"
                              defaultChecked={
                                !!(
                                  subzone.assetLayoutMappingAttributes &&
                                  subzone.assetLayoutMappingAttributes[
                                    `${getFormFieldIndexByKey(
                                      subzone.assetLayoutMappingAttributes,
                                      VEHICLE_FIELD.PUTAWAY_ALLOWED
                                    )}`
                                  ]?.value === 'true'
                                )
                              }
                              onChange={(e) =>
                                handleAttributeClick(
                                  subzone,
                                  e.target.checked,
                                  VEHICLE_FIELD.PUTAWAY_ALLOWED
                                )
                              }
                            />
                          </View.Item>
                        </View>
                      </View.Item>
                      <Divider
                        vertical
                        color="500"
                        attributes={{
                          style: {
                            height: 'var(--st-unit-5)',
                            margin: '0 var(--st-unit-2)',
                          },
                        }}
                      />
                      <View.Item>
                        <Link onClick={() => handleOnDelete(subzone.layoutDistinctName)}>
                          <Icon svg={ActionDelete} color="error" />
                        </Link>
                      </View.Item>
                    </View>
                    {index !== existingSubzones.length - 1 && (
                      <Divider
                        className={
                          style['add-subzone-vehicle-modal__body__subzones_item__separator']
                        }
                        color="300"
                      />
                    )}
                  </>
                );
              })}
            </View.Item>
          </View>
        </View.Item>
      </View>
      <View className={style['add-subzone-vehicle-modal__footer']}>
        <View
          width="100%"
          direction="row"
          justify="end"
          className={style['add-subzone-vehicle-modal__footer__actions']}
        >
          <Button
            variant="secondary"
            attributes={{ style: { width: 'fit-content' } }}
            onClick={handleOnClose}
          >
            <Text>{t('Cancel')}</Text>
          </Button>

          <Button
            variant="primary"
            attributes={{ style: { width: 'fit-content' } }}
            loading={isVehicleUpdating}
            onClick={() => handleOnSubmit()}
          >
            <Text>{t('VehicleDetails.AddSubzoneModal.Title')}</Text>
          </Button>
        </View>
      </View>
    </Modal>
  );
};
