/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Loader } from '@az/starc-ui';
import { useParams } from 'react-router-dom';
import { CYCLE_COUNT_TYPE_CD } from '@inventory/constants/constants';
import { checkCountTypeCd } from '@inventory/utils/utils';
import { useGetFiscalCalendarInfo } from '@inventory/hooks/useGetFiscalCalendarInfo';
import { useDownload } from '@shared/hooks/useDownload';
import { ProgressCard } from './CycleCountDetailsCards/ProgressCard/ProgressCard';
import { AccuracyMeasurementCard } from './CycleCountDetailsCards/AccuracyMeasurementCard/AccuracyMeasurementsCard';
import { TopVariancesCard } from './CycleCountDetailsCards/TopVariancesCard';
import styles from './CycleCountDetailsOverview.module.scss';

export const CycleCountDetailsOverview = () => {
  const paramData = useParams();
  const countTypeCdValue = paramData.countTypeCd || '';
  const countTypeCd = checkCountTypeCd(countTypeCdValue);

  const { contentRef } = useDownload();
  const { isLoading } = useGetFiscalCalendarInfo();

  const isRBCCFlow = countTypeCd === CYCLE_COUNT_TYPE_CD.RBCC;

  return (
    <>
      {isLoading ? (
        <View className={styles.loader}>
          <Loader variant="spinner" color="primary" size="large" />
        </View>
      ) : (
        <div ref={contentRef}>
          <View gap={6} padding={[6, 6]} attributes={{ style: { margin: '0px' } }} width="100%">
            <View direction="row" gap={4}>
              <View.Item grow>
                <ProgressCard showFilter={!isRBCCFlow} isRBCCFlow={isRBCCFlow} />
              </View.Item>
              <View.Item grow>
                <AccuracyMeasurementCard isRBCCFlow={isRBCCFlow} />
              </View.Item>
            </View>
            <View gap={4}>
              <View.Item grow>
                <TopVariancesCard isRBCCFlow={isRBCCFlow} />
              </View.Item>
            </View>
          </View>
        </div>
      )}
    </>
  );
};
