/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { Table } from '@shared/components/Table/Table.tsx';
import {
  LOCATION_ASSOCIATED_SKU_TABLE_COLUMNS,
  TableStylingVariants,
} from '@shared/components/Table/tableConstants.ts';
import { Text, View } from '@az/starc-ui';
import { Props } from '@mdm/pages/RDM/LocationManager/Location/Detail/LocationAssociatedSkuList.types.ts';
import {
  DEFAULT_PAGE,
  ENTITY_ASSOCIATIONS,
  LOCATION_ASSOCIATED_SKUS_PAGE_SIZE,
  PAGE_SIZE,
  PRODUCT_ID_TYPE,
  SORT_ORDER,
} from '@shared/constants/constants.ts';
import { useEffect, useState } from 'react';
import { LicensePlateSearch } from '@az/starc-ui-icons';
import { EmptyState } from '@shared/components/EmptyState/EmptyState.tsx';
import { useTranslation } from 'react-i18next';
import { mapLocationAssociatedSkuTableRows } from '@mdm/utils/table/tableUtils.tsx';
import { LOCATION_ASSOCIATED_SKU_ROWS } from '@shared/components/Table/tableMockData.ts';
import { useGetInventory } from '@shared/services/hooks/useGetInventory.ts';
import { useGetWarehouseProducts } from '@shared/services/hooks/useGetWarehouseProducts.ts';
import { LocationAssociatedSkuListType } from '@mdm/types/schema.type.ts';
import { PAGE_URLS } from '@shared/constants/routes.ts';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_SCOPE, LAYOUT_ENTITY_CODE } from '@mdm/constants/constants.ts';
import { normalizeDistinctName } from '@mdm/utils/utils.ts';

export const LocationAssociatedSkuList = ({ locationId, locationData }: Props) => {
  /* State */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [productIds, setProductIds] = useState<string[]>([]);
  const [associatedSku, setAssociatedSku] = useState<LocationAssociatedSkuListType[]>([]);

  /* Constants */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { refetch: getInventoryProduct, isFetching: isInventoryLoading } = useGetInventory(
    {
      scopeOfInventory: [INVENTORY_SCOPE.LOCATION],
      locations: locationId ? [normalizeDistinctName(locationId, LAYOUT_ENTITY_CODE.LOCATION)] : [],
    },
    false
  );

  const { refetch: getProductSearchData, isFetching: isProductLoading } = useGetWarehouseProducts(
    {
      productIdType: PRODUCT_ID_TYPE.PRODUCT_ID,
      products: productIds.length ? productIds : [],
      currentPage: DEFAULT_PAGE - 1,
      pageSize: PAGE_SIZE,
      sortBy: 'productId',
      direction: SORT_ORDER.ASC,
      entityAssociations: [ENTITY_ASSOCIATIONS.PRODUCT_CODES],
    },
    false
  );

  /* Functions */
  const onViewDetails = (product: LocationAssociatedSkuListType) => {
    navigate(PAGE_URLS.PRODUCT_DETAILS(product.sku));
  };

  /* Hooks */
  useEffect(() => {
    if (locationData) {
      const products = (locationData?.[0]?.products || []).map((product) => {
        return product.productId;
      });

      setProductIds(products);
    }
  }, [setProductIds, locationData]);

  useEffect(() => {
    (async () => {
      if (productIds && productIds.length && locationId) {
        const inventoryData = await getInventoryProduct();
        const productSearchData = await getProductSearchData();

        // combine inventory and product search data
        const combinedProductData = (productSearchData.data?.content || []).map((product) => {
          const inventoryProducts = (inventoryData.data || []).filter(
            (inventoryItem: { productId: string }) => product.productId === inventoryItem.productId
          );

          const foundProduct = inventoryProducts.length
            ? inventoryProducts[0]
            : {
                buckets: {
                  AVAILABLE: 0,
                  ON_YARD: 0,
                  RECEIVED: 0,
                  ALLOCATED: 0,
                  PICKED: 0,
                  BLOCKED: 0,
                  DAMAGED: 0,
                  LOADED: 0,
                  SHIPPED: 0,
                },
              };

          for (const inventoryProduct of inventoryProducts) {
            for (const key in foundProduct.buckets) {
              if (Object.prototype.hasOwnProperty.call(foundProduct.buckets, key)) {
                (foundProduct.buckets as Record<string, number>)[key] +=
                  (inventoryProduct.buckets as Record<string, number>)[key] || 0;
              }
            }
          }

          return {
            ...product,
            ...foundProduct,
          };
        });

        if (combinedProductData && combinedProductData.length) {
          const associatedSkuData = combinedProductData.map((product) => {
            const partNumbers = (product.codes || [])
              .filter((code) =>
                ['partnumber', 'part_number'].includes(code.productCodeType?.toLowerCase() || '')
              )
              .map((code) => code.productCodeValue);

            partNumbers.push(product.partNumber || '');

            const receivedQty = product.buckets?.RECEIVED ? Number(product.buckets?.RECEIVED) : 0;

            return {
              sku: product.productId,
              description: product.prodDesc?.[0]?.prodDesc || '',
              quantity: receivedQty,
              partNumber: partNumbers.join(', '),
            };
          });
          setAssociatedSku(associatedSkuData);
        }
      }
    })();
  }, [locationId, productIds, getInventoryProduct, getProductSearchData]);

  return (
    <View gap={4} width="100%" height="100%">
      <View direction="row">
        <View.Item grow>
          <Text size="125" weight="bold">
            {t('LocationDetails.TotalSkusInLocation', {
              count: LOCATION_ASSOCIATED_SKU_ROWS.length,
            })}
          </Text>
        </View.Item>
      </View>

      {!locationId ? (
        <View padding={6}>
          <EmptyState
            svg={LicensePlateSearch}
            subtitle={t('Empty.LocationSkus.Subtitle')}
            text={t('Empty.LocationSkus.Text')}
          />
        </View>
      ) : (
        <View>
          <Table
            columns={LOCATION_ASSOCIATED_SKU_TABLE_COLUMNS}
            rows={mapLocationAssociatedSkuTableRows(associatedSku, onViewDetails)}
            isPaginated={true}
            isCheckboxDisabled={false}
            pageSize={LOCATION_ASSOCIATED_SKUS_PAGE_SIZE}
            defaultPage={currentPage}
            isCreditItem={false}
            isCheckboxTable={false}
            isLoading={isInventoryLoading || isProductLoading}
            styleVariant={TableStylingVariants.FILLED}
            totalPages={Math.ceil(
              (LOCATION_ASSOCIATED_SKU_ROWS.length || DEFAULT_PAGE - 1) / PAGE_SIZE
            )}
            onPageChange={(newPage) => {
              setCurrentPage(newPage);
            }}
            onSort={() => ({})}
          />
        </View>
      )}
    </View>
  );
};
